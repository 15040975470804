import { Injectable } from "@angular/core";
@Injectable()
export class ContactUs{
    ContactTitle:string;
    AddressLine1:string;
    AddressLine2:string;
    AddressLine3:string;
    AddressLine4:string;
    Tel:string;
    Fax:string;
    Email:string;
    Hours?:string;
    Country:string;
    Group?: string;
}