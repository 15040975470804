import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { UserRouteGuard } from '../core/services/route-guards/user-route.guard';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { LayoutComponent } from '../layout/layout.component';
import { SupportDocumentsComponent } from './support-documents/support-documents.component';
import { RestrictedZipCodesComponent } from './restricted-zip-codes/restricted-zip-codes.component';
import { SupplyOrderComponent } from './supply-order/supply-order.component';
import { OrderSuppliesHistoryComponent } from './supply-order/order-supplies-history/order-supplies-history.component';

@NgModule({
  imports: [RouterModule.forChild([
    {
      path: '',
      canActivate: [UserRouteGuard],
      component: LayoutComponent,
      children: [
        {
          path: 'contact-us',
          component: ContactUsComponent,
        },
        {
          path: 'support-documents',
          component: SupportDocumentsComponent,
        },
        {
          path: 'supply-order',
          component: SupplyOrderComponent,
        },
        {
          path: 'order-supplies-history',
          component: OrderSuppliesHistoryComponent,
        },
        {
          path: 'restricted-zip-codes',
          component: RestrictedZipCodesComponent,
        },
      ],
    },
  ])],
  exports: [RouterModule],
})
export class SupportRoutingModule { }
