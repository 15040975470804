import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ValidationService } from '../../../shared/services/validation/validation.service';
import { AddressBookService } from '../../services/address-book.service';
import { AddressBook } from '../../models/address-book.model';
import { ZipCodeService } from '../../../shared/services/zip-code/zip-code.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NotificationService } from '../../../shared/services/notification/notification.service';
import { NotificationType } from '../../../shared/models/notification-type';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import * as _ from 'lodash';
import { ErrorHandlerService } from '../../../shared/services/error-handler/error-handler.service';

@Component({
  selector: 'upsc-manage-address',
  templateUrl: './manage-address.component.html',
  styleUrls: ['./manage-address.component.scss'],
})
export class ManageAddressComponent implements OnInit, OnDestroy {

  public formGroup: UntypedFormGroup;
  public editMode = false;
  public countriesList: any[];
  public contactId: string;
  public isZipMandatory = false;
  public isStateProvince = false;
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private addressbookservice: AddressBookService,
    public addressBook: AddressBook,
    private zipcodeservice: ZipCodeService,
    private notificationService: NotificationService,
    private dialog: MatDialog,
    private selfdialogRef: MatDialogRef<ManageAddressComponent>,
    private formBuilder: UntypedFormBuilder,
    private validationService: ValidationService,
    private translateService: TranslateService,
    private errorHandlerService: ErrorHandlerService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.getCountries();
  }

  ngOnInit() {
    this.addressBook = new AddressBook();
    this.addressBook.Country = 'US';
    this.initForm();

    if (this.data) {
      this.editMode = this.data.editMode ? this.data.editMode : false;
      if (this.editMode) {
        this.contactId = this.data.contactId ? this.data.contactId : '';
        this.editAddress(this.contactId);
      }
    }
  }

  initForm() {
    this.formGroup = this.formBuilder.group({
      nickName: [this.addressBook.NickName, Validators.required],
      firstName: [this.addressBook.FirstName, Validators.required],
      lastName: [this.addressBook.LastName, Validators.required],
      companyName: [this.addressBook.CompanyName, Validators.required],
      email: [this.addressBook.Email, this.validationService.emailFormatValidator()],
      telephoneNo: [this.addressBook.TelephoneNo, Validators.compose([Validators.required, Validators.pattern('^[0-9]*$'), Validators.minLength(10)])],
      faxNo: [this.addressBook.FaxNo],
      streetAddress: [this.addressBook.StreetAddress, Validators.required],
      apartmentSuite: [this.addressBook.ApartmentSuite],
      country: [this.addressBook.Country, Validators.required],
      zip: [this.addressBook.Zip, Validators.required],
      city: [this.addressBook.City, Validators.required],
      state: [this.addressBook.State, Validators.required],
      isResidential: [this.addressBook.IsResidential],
      isExpress: [this.addressBook.IsExpress],
    });

    this.onCountryChange(this.addressBook.Country);
    this.onValueChanges();
  }

  populateAddress() {
    this.formGroup.setValue({
      nickName: this.addressBook.NickName,
      firstName: this.addressBook.FirstName,
      lastName: this.addressBook.LastName,
      companyName: this.addressBook.CompanyName,
      email: this.addressBook.Email,
      telephoneNo: this.addressBook.TelephoneNo,
      faxNo: this.addressBook.FaxNo,
      streetAddress: this.addressBook.StreetAddress,
      apartmentSuite: this.addressBook.ApartmentSuite,
      country: this.addressBook.Country,
      zip: this.addressBook.Zip,
      city: this.addressBook.City,
      state: this.addressBook.State,
      isResidential: this.addressBook.IsResidential,
      isExpress: this.addressBook.IsExpress,
    });
    this.onCountryChange(this.addressBook.Country);
    this.onValueChanges();
  }

  onValueChanges() {
    if (this.formGroup.controls.companyName.value) {
      this.setCompanyValidation(true);
    } else {
      this.setCompanyValidation(false);
    }

    this.formGroup.controls.zip.valueChanges
      .pipe(debounceTime(2000)).subscribe((value) => {
        if (value)
          this.getAddressbyZipCode(value);
      });

    /* this.formGroup.controls.firstName.valueChanges
          .takeUntil(this.destroy$)
          .subscribe((val) => {
              if (val || this.formGroup.controls.lastName.value)
                  this.setCompanyValidation(false);
              else this.setCompanyValidation(true);
          });
      this.formGroup.controls.lastName.valueChanges
          .takeUntil(this.destroy$)
          .subscribe((val) => {
              if (val || this.formGroup.controls.firstName.value)
                  this.setCompanyValidation(false);
              else this.setCompanyValidation(true);
          });
      this.formGroup.controls.companyName.valueChanges
          .takeUntil(this.destroy$)
          .subscribe((val) => {
              if (val)
                  this.setCompanyValidation(true);
              else this.setCompanyValidation(false);
          });*/
  }

  private setCompanyValidation(isRequired: boolean) {
    const lastName = this.formGroup.controls.lastName;
    const firstName = this.formGroup.controls.firstName;
    const companyName = this.formGroup.controls.companyName;
    this.setControlValidation(lastName, !isRequired);
    this.setControlValidation(firstName, !isRequired);
    this.setControlValidation(companyName, isRequired);
  }

  private setControlValidation(control: AbstractControl, isRequired: boolean) {
    if (isRequired) {
      this.validationService.setFormControlValidators(control, Validators.compose([Validators.required]));
    } else {
      this.validationService.clearFormControlValidators([control]);
    }
  }

  getCountries() {
    this.addressbookservice.getCountries().subscribe(
      (countries) => {
        if (countries) {
          this.countriesList = _.sortBy(countries, ['CountryName']);
        }

        this.onCountryChange(this.addressBook.Country);
      },
      (err) => {
        this.notificationService.notify(
          this.errorHandlerService.getHttpErrorMessage(err),
          this.translateService.instant('CountriesLoadingError_msg'),
          NotificationType.ERROR);
      });
  }

  editAddress(contactId: string) {
    this.addressbookservice.getAddressBook(contactId).subscribe(
      (addressBook) => {
        this.addressBook = addressBook;
        this.populateAddress();
      },
      (err) => {
        this.notificationService.notify(
          this.errorHandlerService.getHttpErrorMessage(err),
          this.translateService.instant('errorLoadingAddress'),
          NotificationType.ERROR);
      });
  }

  getAddressbyZipCode(event) {
    if (!['US', 'CA', 'PR', 'VI', 'GU'].includes(this.formGroup.controls.country.value)) {
      return;
    }

    this.zipcodeservice.getAddressByZipCode(event).subscribe(
      (address) => {
        if (address) {
          this.formGroup.controls.city.setValue(address.City.trim());
          this.formGroup.controls.state.setValue(address.State.trim());
        }
      },
      (err) => {
        this.notificationService.notify(
          this.errorHandlerService.getHttpErrorMessage(err),
          this.translateService.instant('ErrorLoadingAddress_msg'),
          NotificationType.ERROR);
      });
  }

  updateAddressBook() {
    if (this.formGroup.valid) {
      this.mapFormValueToAddressObject();
      this.addressbookservice.saveAddressBook(this.addressBook).subscribe(
        (response) => {
          this.selfdialogRef.close({ result: 'success' });
          this.notificationService.notify('', this.translateService.instant('AddressUpdatedInfo'), NotificationType.SUCCESS);         
        },
        (err) => {
          // [MV3-1774] If update failed, don't close popup instead allow the user to modify the inputs
          // this.selfdialogRef.close({ result: 'failed' });
          this.notificationService.notify(
            this.errorHandlerService.getHttpErrorMessage(err),
            this.translateService.instant('updatingAddressFailed'),
            NotificationType.ERROR);
        });
    }
  }

  saveAddressBook() {
    if (this.formGroup.valid) {
      this.mapFormValueToAddressObject();
      this.addressbookservice.saveAddressBook(this.addressBook).subscribe(
        (response) => {
          this.selfdialogRef.close({ result: 'success' });
          this.notificationService.notify('', this.translateService.instant('AddressUpdatedInfo'), NotificationType.SUCCESS);         
        },
        (err) => {
          // [MV3-1774] If update failed, don't close popup instead allow the user to modify the inputs
          // this.selfdialogRef.close({ result: 'failed' });
          this.notificationService.notify(
            this.errorHandlerService.getHttpErrorMessage(err),
            this.translateService.instant('addingAddressFailed'),
            NotificationType.ERROR);
        });
    }
  }

  onCountryChange(val) {
    if (this.countriesList && this.countriesList.length) {

      const countryObject = this.countriesList.filter(x => x.CountryCode === val);
      if (countryObject && countryObject.length > 0) {
        this.isZipMandatory = countryObject[0].IsPostalCodeAware;
        this.isStateProvince = countryObject[0].IsStateAware;
      }
      const zipControl = this.formGroup.controls.zip;
      const stateControl = this.formGroup.controls.state;

      this.setControlValidation(zipControl, this.isZipMandatory);
      this.setControlValidation(stateControl, this.isStateProvince);
    } else {
      return;
    }
  }

  populateProvinceRegion() {
    if (this.addressBook.Country.toLowerCase() !== 'us') {
      this.addressBook.ProvinceRegion = this.addressBook.State;
    }
  }

  mapFormValueToAddressObject() {
    this.addressBook.NickName = this.formGroup.controls.nickName.value;
    this.addressBook.FirstName = this.formGroup.controls.firstName.value;
    this.addressBook.LastName = this.formGroup.controls.lastName.value;
    this.addressBook.CompanyName = this.formGroup.controls.companyName.value;
    this.addressBook.Email = this.formGroup.controls.email.value;
    this.addressBook.TelephoneNo = this.formGroup.controls.telephoneNo.value;
    this.addressBook.FaxNo = this.formGroup.controls.faxNo.value;
    this.addressBook.StreetAddress = this.formGroup.controls.streetAddress.value;
    this.addressBook.ApartmentSuite = this.formGroup.controls.apartmentSuite.value;
    this.addressBook.Country = this.formGroup.controls.country.value;
    this.addressBook.Zip = this.formGroup.controls.zip.value;
    this.addressBook.City = this.formGroup.controls.city.value;
    this.addressBook.State = this.formGroup.controls.state.value;
    this.addressBook.ProvinceRegion = this.formGroup.controls.state.value;
    this.addressBook.IsResidential = this.formGroup.controls.isResidential.value;
    this.addressBook.IsExpress = this.formGroup.controls.isExpress.value;
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
