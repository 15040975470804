<div class="supply-order-edit-email ups-dialog">
    <div class="dialog-header">
        <h2 mat-dialog-title>{{ 'EditMailingAddress'|translate }}</h2>
        <mat-dialog-actions>
        </mat-dialog-actions>
        <button type="button" mat-raised-button class="icon-button close-button" mat-dialog-close>
            <mat-icon>close</mat-icon>
        </button>
    </div>

    <mat-dialog-content>
        <ng-container [ngTemplateOutlet]="form"></ng-container>
    </mat-dialog-content>
</div>

<ng-template #form>
    <form class="ups-form" [formGroup]="formGroup" (ngSubmit)="updateMailAddress($event, formGroup.value)">
        <div class="form-body">
            <mat-form-field>
                <mat-label>{{ 'FirstName'|translate }}</mat-label>
                <input formControlName="Firstname"
                       matInput
                       required
                       onfocus="this.select();"
                       type="text"
                       autocomplete="off">
            </mat-form-field>

            <mat-form-field>
                <mat-label>{{ 'LastName'|translate }}</mat-label>
                <input formControlName="Lastname"
                       required
                       matInput
                       onfocus="this.select();"
                       type="text"
                       autocomplete="off">
            </mat-form-field>

            <mat-form-field>
                <mat-label>{{ 'CompanyName'|translate }}</mat-label>
                <input formControlName="CompanyName"
                       required
                       matInput
                       onfocus="this.select();"
                       type="text"
                       autocomplete="off">
            </mat-form-field>

            <mat-form-field>
                <mat-label>{{ 'StreetAddress'|translate }}</mat-label>
                <input formControlName="StreetAddress"
                       required
                       matInput
                       onfocus="this.select();"
                       type="text"
                       autocomplete="off">
            </mat-form-field>

            <mat-form-field>
                <mat-label>{{ 'SuiteOrApartment'|translate }}</mat-label>
                <input formControlName="ApartmentSuite"
                       matInput
                       onfocus="this.select();"
                       type="text"
                       autocomplete="off">
            </mat-form-field>

            <mat-form-field>
                <mat-label>{{ 'Zipcode'|translate }}</mat-label>
                <input formControlName="zipCode"
                       required
                       matInput
                       onfocus="this.select();"
                       type="text"
                       autocomplete="off">
            </mat-form-field>

            <mat-form-field>
                <mat-label>{{ 'City'|translate }}</mat-label>
                <input formControlName="City"
                       required
                       matInput
                       onfocus="this.select();"
                       type="text"
                       autocomplete="off">
            </mat-form-field>

            <mat-form-field>
                <mat-label>{{ 'State'|translate }}</mat-label>
                <input formControlName="State"
                       required
                       matInput
                       onfocus="this.select();"
                       type="text"
                       autocomplete="off">
            </mat-form-field>

            <mat-form-field>
                <mat-label>Country</mat-label>
                <mat-select formControlName="Country" required>
                    <mat-option *ngFor="let country of countriesList" value="{{country.CountryCode}}">
                        {{ country.CountryName }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field>
                <mat-label>{{ 'Phone'|translate }}</mat-label>
                <input formControlName="Phone"
                       required
                       matInput
                       onfocus="this.select();"
                       type="text"
                       pattern="\d*"
                       autocomplete="off">
            </mat-form-field>

            <mat-form-field>
                <mat-label>{{ 'Fax'|translate }}</mat-label>
                <input matInput
                       onfocus="this.select();"
                       type="text"
                       formControlName="Fax"
                       autocomplete="off">
            </mat-form-field>

            <mat-form-field>
                <mat-label>{{ 'EmailAddress'|translate }}</mat-label>
                <input formControlName="EmailAddress"
                       required
                       matInput
                       onfocus="this.select();"
                       type="text"
                       autocomplete="off">
            </mat-form-field>
        </div>

        <div class="form-footer">
            <div class="form-actions">
                <button type="submit" mat-raised-button class="form-action">{{ 'Update'|translate }}</button>
            </div>
        </div>
    </form>
</ng-template>
