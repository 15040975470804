<div class="order-supplies-history white-content-page">
    <upsc-page-header [pageTitle]="'supplyOrder.orderSuppliesHistory' | translate">
        <div page-actions>
            <button type="button" mat-raised-button class="alternative-button action" (click)="goBack()">
                <i class="material-icons">keyboard_backspace</i> {{ 'supplyOrder.backtoOrderSupplies' | translate }}
            </button>
        </div>
    </upsc-page-header>
    <div class="table-wrapper" [class.responsive-table]="dtOptions.responsive">
        <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="stripe" [hidden]="!orders">
            <thead>
                <tr class="my-tr">
                    <th class="all trigger-column"></th>
                    <th>{{ 'supplyOrder.orderDate' | translate }}</th>
                    <th>{{ 'ShipTo' | translate}}</th>
                    <th>{{ 'supplyOrder.orderNumber' | translate }} </th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let order of orders | paginate:paginate">
                    <td class="trigger-cell"></td>
                    <td> {{ order.RequestDate | date : 'MM/dd/yyyy' }} </td>
                    <td class="address"> 
                       <div> {{ order.StreetAddress }} </div>
                       <div class="text-ellipsis">
                            {{ order.ApartmentSuite }} {{ order.City }} {{ order.State }} {{ order.Country }} {{ order.Zip }}
                        </div>
                    </td>
                    <td class="order-number"> {{ order.OrderNumber }} </td>
                    <td class="details">
                        <button mat-raised-button class="alternate-button" (click)="openOrderDetail(order.RequestId)">
                            {{ 'ViewDetails' | translate }}
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <pagination-controls
        [directionLinks]="true" 
        [previousLabel]="''" 
        [nextLabel]="''" 
        [autoHide]="true" 
        (pageChange)="onPageChanged($event)"></pagination-controls>
        <div class="instruction" >
                {{ 'supplyOrder.contactService' | translate }}
        </div>
</div>