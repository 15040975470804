import { Component, Inject, OnInit, ViewEncapsulation, ViewChild, ElementRef, ChangeDetectorRef, AfterContentChecked, ChangeDetectionStrategy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SupplyOrderService } from '../../services/supply-order.service';
import { SiteConfig } from '../../../../config/site-config';
import * as _ from 'lodash';
import { SpinnerService } from '../../../core/services/spinner/spinner.service';
import { NotificationService } from '../../../shared/services/notification/notification.service';
import { NotificationType } from '../../../shared/models/notification-type';
import { ErrorHandlerService } from '../../../shared/services/error-handler/error-handler.service';
import { Subject } from 'rxjs';
import { PaginationInstance } from 'ngx-pagination';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'upsc-order-detail',
  templateUrl: './order-detail.component.html',
  styleUrls: ['./order-detail.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class OrderDetailComponent implements OnInit {

  public requestId: string;
  public dtOptions: any = {};
  public dtTrigger: Subject<any> = new Subject<any>();

  private currentPage = 1;
  private pageSize = 50;
  public totalItems = 0;
  public paginate: PaginationInstance;

  public supplyOrderDetail: any;
  public supplies: any[] = ["SmallBox",
    "MediumBox",
    "LargeBox",
    "Envelopes",
    "PeelStick",
    "PaddedPack",
    "ThermalRoll",
    "Pouch",
    "FedExDomesticAirbillPouch",
    "FedexInternationalAirbillPouch",
    "FedExInternationalAirWayBill",
    "FedexEnvelopePouch",
    "FedexEnvelopeNoPouch",
    "FedexLegalSizeEnvelope",
    "FedexSmallPak",
    "FedexLargePak",
    "FedexPaddedPak",
    "FedexSturdyPak",
    "FedexSmallBox",
    "FedexMediumBox",
    "FedexLargeBox",
    "FedexTube",
    "FedexLaserLabel",
    "InternationalPouch",
  ]
  public orderItems: any[] = [];

  constructor(@Inject(MAT_DIALOG_DATA) data: any,
    private notificationService: NotificationService,
    private errorHandlerService: ErrorHandlerService,
    private spinnerService: SpinnerService,
    private translateService: TranslateService,private cdRef : ChangeDetectorRef,
    private supplyOrderService: SupplyOrderService) {
    if (data && data.requestId) {
      this.requestId = data.requestId;
      this.getSupplyOrderDetails();
    }

  }

  ngOnInit() {    
    
    this.dtOptions = SiteConfig.tableConfig;
  
    this.updatePaginateConfig();
    this.cdRef.detectChanges();
  }
  private updatePaginateConfig() {
    this.paginate = {
      
        totalItems: this.totalItems,
        currentPage: this.currentPage,
        itemsPerPage: this.pageSize,
    };
  }

  getSupplyOrderDetails() {
    this.spinnerService.show();
    this.supplyOrderService.getSupplyOrderDetails(this.requestId).subscribe(
      (orderDetail) => {
        this.spinnerService.hide();
        this.supplyOrderDetail = orderDetail;
            this.dtTrigger.next(null);
            Object.keys(orderDetail).map((key) => {
          if ((_.includes(this.supplies, key) ? +orderDetail[key] > 0 ? 1 : 0 : 0) > 0) {
            this.totalItems = this.totalItems + 1;
            this.orderItems.push({ name: key, value: (+orderDetail[key]) });
          }
        });
      },
      (error) => {
        this.spinnerService.hide();
        this.dtTrigger.next(null);
        this.notificationService.notify(
          this.errorHandlerService.getHttpErrorMessage(error),
          this.translateService.instant('supplyOrder.failedGettingOrderDetails'),
          NotificationType.ERROR);
      }
    )
  }

}
