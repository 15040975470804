import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder,UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core'
import * as _ from 'lodash';
import { debounceTime } from 'rxjs/operators';

import { ZipCodeService } from '../../../shared/services/zip-code/zip-code.service';
import { AddressBookService } from '../../../address-book/services/address-book.service';
import { MailAddressService } from '../../../shared/services/mail-address/mail-address.service';
import { MailAddress } from '../../../shared/models/mail-address.model';
import { SupplyOrderService } from '../../services/supply-order.service';
import { SupplyOrder } from '../../models/supply-order';
import { NotificationService } from '../../../shared/services/notification/notification.service';
import { NotificationType } from '../../../shared/models/notification-type';
import { ErrorHandlerService } from '../../../shared/services/error-handler/error-handler.service';


@Component({
  selector: 'upsc-supply-order-edit-email',
  templateUrl: './supply-order-edit-email.component.html',
  styleUrls: ['./supply-order-edit-email.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class SupplyOrderEditEmailComponent implements OnInit {
  public formGroup:UntypedFormGroup;
  public totalOrderItems: number = 0;
  public countriesList: any[];
  public statesList: any[];
  public specialInstructions: string;
  public pickSupply: boolean = true;
  public confirmMailingAddress: boolean = false;
  public orderComplete: boolean = false;
  public customMessage: string;
  public showAlert: boolean = false;

  constructor(private addressbookservice: AddressBookService,
    private mailAddressService: MailAddressService,
    public mailAddress: MailAddress,
    private supplyOrderService: SupplyOrderService,
    public supplyOrder: SupplyOrder,
    private zipcodeservice: ZipCodeService,
    private notificationService: NotificationService,
    private errorHandlerService: ErrorHandlerService,
    private translateService: TranslateService,
    private formBuilder: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private selfDialogRef: MatDialogRef<SupplyOrderEditEmailComponent>,
    public dialogRef: MatDialogRef<SupplyOrderEditEmailComponent>) {
    this.GetCountries();
    this.GetMailAddress();
  }

  ngOnInit() {
    this.formGroup = this.formBuilder.group({
      Firstname: [''],
      Lastname: [''],
      CompanyName: [''],
      StreetAddress: [''],
      ApartmentSuite: [''],
      zipCode: [''],
      City: [''],
      State: [''],
      Country: [''],
      Phone: [''],
      Fax: [''],
      EmailAddress: ['']
    });
    this.monitorValueChanges();
  }

  private monitorValueChanges() {
    this.formGroup.controls.zipCode.valueChanges
      .pipe(debounceTime(2000))
      .subscribe((value) => {
        if (value) {
          this.getAddressbyZipCode(value);
        }
      })
  }

  GetCountries() {
    this.addressbookservice.getCountries().subscribe(countries => {
      if (countries)
        this.countriesList = _.sortBy(countries, ['CountryName']);
    }, err => {
      this.notificationService.notify(
        this.errorHandlerService.getHttpErrorMessage(err),
        'Error Loading Countries',
        NotificationType.ERROR);

    });
  }

  GetMailAddress() {
    this.mailAddressService.getMailAddress().subscribe(mailAddress => {

      if (mailAddress && mailAddress.length > 0)
        this.initiateMailAddress(mailAddress[0]); //this.mailAddress = mailAddress[0];
    }, err => {
      this.notificationService.notify(
        this.errorHandlerService.getHttpErrorMessage(err),
        'Error Loading Mail Address',
        NotificationType.ERROR);

    });
  }

  validateMailAddress(): boolean {
    for (let control in this.formGroup.controls) {
      if (this.formGroup.controls[control].status == 'INVALID') {

        this.notificationService.notify('Required: Please Enter ' + control, 'Validation Failed', NotificationType.ERROR);
        return false;
      }
    }

    return true;
  }

  getAddressbyZipCode(event) {
    if (!['US', 'CA', 'PR', 'VI', 'GU'].includes(this.formGroup.controls.Country.value)) {
      return;
    }

    this.zipcodeservice.getAddressByZipCode(this.formGroup.controls.zipCode.value).subscribe(response => {

      if (response) {
        this.formGroup.controls['City'].setValue(response.City);
        this.formGroup.controls['State'].setValue(response.State);
        this.formGroup.controls['Country'].setValue(response.Country);
      }
    }, error => {
      this.notificationService.notify(
        this.errorHandlerService.getHttpErrorMessage(error),
        this.translateService.instant('ErrorLoadingAddress_msg'),
        NotificationType.ERROR);
    });
  }

  initiateMailAddress(mailAddress) {
    this.supplyOrder.FirstName = mailAddress.FirstName;
    this.supplyOrder.LastName = mailAddress.LastName;
    this.supplyOrder.ApartmentSuite = mailAddress.ApartmentSuite;
    this.supplyOrder.StreetAddress = mailAddress.StreetAddress;
    this.supplyOrder.Zip = mailAddress.Zip;
    this.supplyOrder.City = mailAddress.City;
    this.supplyOrder.State = mailAddress.State;
    this.supplyOrder.Country = mailAddress.Country;
    this.supplyOrder.TelNum = mailAddress.TelephoneNo;
    this.supplyOrder.FaxNo = mailAddress.FaxNo;
    this.supplyOrder.UserEmail = mailAddress.Email;
    this.supplyOrder.CompanyName = mailAddress.CompanyName;

    this.formGroup.patchValue({
      firstName: mailAddress.FirstName,
      lastName: mailAddress.LastName,
      company: mailAddress.CompanyName,
      zipCode: mailAddress.Zip,
      city: mailAddress.City,
      state: mailAddress.State,
      country: mailAddress.Country,
      address1: mailAddress.StreetAddress,
      address2: mailAddress.ApartmentSuite,
      email: mailAddress.Email,
      phone: mailAddress.TelephoneNo,
    });
    this.editMailAddress();
  }

  editMailAddress() {
    this.formGroup = this.formBuilder.group({
      Firstname: [this.supplyOrder.FirstName],
      Lastname: [this.supplyOrder.LastName],
      CompanyName: [this.supplyOrder.CompanyName],
      StreetAddress: [this.supplyOrder.StreetAddress],
      ApartmentSuite: [this.supplyOrder.ApartmentSuite],
      zipCode: [this.supplyOrder.Zip],
      City: [this.supplyOrder.City],
      State: [this.supplyOrder.State],
      Country: [this.supplyOrder.Country],
      Phone: [this.supplyOrder.TelNum],
      Fax: [this.supplyOrder.FaxNo],
      EmailAddress: [this.supplyOrder.UserEmail]
    });
    this.monitorValueChanges();
  }

  updateMailAddress(event, form) {
    event.preventDefault();

    if (this.validateMailAddress()) {
      this.supplyOrder.FirstName = this.formGroup.controls.Firstname.value;
      this.supplyOrder.LastName = this.formGroup.controls.Lastname.value;
      this.supplyOrder.CompanyName = this.formGroup.controls.CompanyName.value;
      this.supplyOrder.ApartmentSuite = this.formGroup.controls.ApartmentSuite.value
      this.supplyOrder.StreetAddress = this.formGroup.controls.StreetAddress.value
      this.supplyOrder.Zip = this.formGroup.controls.zipCode.value
      this.supplyOrder.City = this.formGroup.controls.City.value
      this.supplyOrder.State = this.formGroup.controls.State.value
      this.supplyOrder.Country = this.formGroup.controls.Country.value
      this.supplyOrder.TelNum = this.formGroup.controls.Phone.value
      this.supplyOrder.FaxNo = this.formGroup.controls.Fax.value
      this.supplyOrder.UserEmail = this.formGroup.controls.EmailAddress.value

      this.selfDialogRef.close({ result: 'success', data: this.supplyOrder });

    }

  }
}
