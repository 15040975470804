<div class="address-books white-content-page">
    <upsc-page-header [pageTitle]="'AddressBook'|translate">
    </upsc-page-header>

    <div class="page-center">
        <div class="page-actions">
            <mat-form-field [subscriptSizing]="'dynamic'">
                <mat-label>{{'Search'|translate}}</mat-label>
                <input (ngModelChange)="searchCarrierAddressBook($event)"
                       [(ngModel)]="searchText"
                       autocomplete="off"
                       matInput
                       name="trackingNumber"
                       type="text">
            </mat-form-field>

            <div class="button-group">
                <button (click)="addAddressBook()" class="page-action" mat-raised-button type="button">
                    {{'AddNewAddress'|translate}}
                </button>
                <div class="file-selector">
                    <input #importFile
                           (change)="uploadAddressBook($event)"
                           accept="{{ allowedFileTypes }}"
                           class="file-input"
                           id="fileInput"
                           type="file">
                    <button mat-raised-button class="file-input-button mat-raised-button alternative-button"
                           (click)="importFile.click()">
                        {{ 'importAddressBook'|translate }}
                    </button>
                </div>
                <!-- <upsc-file-selector 
                                    [label]="'importAddressBook'|translate"
                                    [fileTypes]="allowedFileTypes"
                                    [buttonClass]="'alternative-button'"
                                    [isMultiple]="false"
                                    (fileSelectionChanged)="uploadAddressBook($event)"></upsc-file-selector> -->
            </div>
            <upsc-s3-file-downloader [bucketName]="'members-xlsx.upsc'"
                                     [buttonClass]="'transparent-button'"
                                     [buttonText]="'viewInstructions'|translate"
                                     [fileName]="'AddressBookTemplate.xlsx'"></upsc-s3-file-downloader>
        </div>
    </div>

    <div [class.responsive-table]="dtOptions.responsive" class="table-wrapper">
        <table [dtOptions]="dtOptions"
               [dtTrigger]="dtTrigger"
               [hidden]="!addressList"
               class="stripe"
               datatable>
            <thead>
            <tr>
                <th class="all trigger-column"></th>
                <th>
                    {{'NickName'|translate}}
                </th>
                <th>
                    {{'ContactName'|translate}}
                </th>
                <th>
                    {{'CompanyName'|translate}}
                </th>
                <th>
                    {{'Address'|translate}}
                </th>
                <th class="all">
                    {{'Express'|translate}}
                </th>
                <th class="all">
                    {{'Shared'|translate}}
                </th>
                <th class="all">
                </th>
            </tr>
            </thead>
            <tbody #tb>
            <tr *ngFor="let item of addressList | paginate:paginate">
                <td class="trigger-cell"></td>
                <td class="text-uppercase"> {{item.NickName}}
                </td>
                <td class="text-uppercase"> {{item.FirstName + ' ' + item.LastName}}
                </td>
                <td class="text-uppercase"> {{item.CompanyName}}
                </td>
                <td>
                    {{item.StreetAddress + ' ' + item.ApartmentSuite + ', ' + item.City + ', ' + item.State + ', ' +
                item.Zip + ' ' + item.Country
                    }}
                </td>
                <td align="center">
                    <mat-checkbox (click)="ChangeExpress(item.ContactId,item.IsExpress)"
                                  [checked]="item.IsExpress"></mat-checkbox>
                </td>
                <td align="center">
                    <mat-checkbox (click)="ChangeShared(item.ContactId,(item.UserId==0))"
                                  [checked]="item.UserId==0"></mat-checkbox>
                </td>
                <td>
                    <div class="actions">
                        <button (click)="confirmDeleteAddress(item.ContactId)"
                                class="alternative-button"
                                mat-raised-button
                                type="button">
                            {{'Remove'|translate}}
                        </button>
                        <button (click)="editAddress(item.ContactId)" mat-raised-button type="button">
                            {{'Edit'|translate}}
                        </button>
                    </div>
                    <!--<a href="#deleteconfirmation" data-toggle="modal" data-dismiss="modal" class="btn btn-default btn-xs btn-grey text-normal black-font"
                                    (click)="contactId=item.ContactId" >{{'Remove'|translate}}</a>
                                <a href="#manageaddress" data-toggle="modal" data-dismiss="modal" (click)="editAddress(item.ContactId)" class="btn btn-default btn-xs btn-green white-font text-normal" >{{'Edit'|translate}}</a>-->
                </td>
            </tr>
            <tr *ngIf="noRecordsFound">
                <td class="text-center" colspan="7">
                    <span style="color:red;font-size:12px;"> {{'NoRecordsFound'|translate}} </span>
                </td>
            </tr>
            </tbody>
        </table>
        <pagination-controls (pageChange)="onPageChanged($event)"
                             [autoHide]="true"
                             [directionLinks]="true"
                             [nextLabel]="''"
                             [previousLabel]="''"></pagination-controls>
        <upsc-element-blocker *ngIf="isDataLoading"></upsc-element-blocker>
    </div>
</div>
