import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { SlicePipe } from '@angular/common';
import { AddressBookService } from '../services/address-book.service';
import { AddressBook } from '../models/address-book.model';
import { ZipCodeService } from '../../shared/services/zip-code/zip-code.service';
import { Subscription,Subject } from 'rxjs';
import { DialogService } from '../../shared/services/dialog/dialog.service';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { NotificationService } from '../../shared/services/notification/notification.service';
import { NotificationType } from '../../shared/models/notification-type';
import { ManageAddressComponent } from './manage-address/manage-address.component';
import { PaginationInstance } from 'ngx-pagination';
import { SiteConfig } from '../../../config/site-config';
import * as XLSX from 'xlsx';
import { TranslateService } from '@ngx-translate/core';
import { ErrorHandlerService } from '../../shared/services/error-handler/error-handler.service';

type AOA = any[][];

@Component({
  selector: 'upsc-address-book',
  templateUrl: './address-book.component.html',
  styleUrls: ['./address-book.component.scss'],
})
export class AddressBookComponent implements OnInit {
  public addressList: any[];
  public addressBooks: any[];
  public importAddress = [];
  public pageIndex = 1;
  public pageSize = 25;
  public Edit: boolean;
  public noRecordsFound = false;
  public NoMoreRecords = 'NoMoreRecordsFound';
  public isShowMore: boolean;
  public allowedFileTypes = ['csv', 'xlsx', 'xls'];
  private formData: FormData = new FormData();
  public uploadedFile: string;

  // public companySearch: string;
  public contactId: string;
  public searchText: string;
  public subscriptionObj: Subscription;

  public dtOptions: any = {};
  public dtTrigger: Subject<any> = new Subject<any>();
  @ViewChild('importFile', { static: true }) importFile: ElementRef;

  // Pagination
  public paginate: PaginationInstance;
  public tablePageSize = 25;
  public tablePageIndex = 0;
  public isDataLoading = false;
  private totalItems = 0;

  constructor(private addressbookservice: AddressBookService,
    public addressBook: AddressBook,
    private zipcodeservice: ZipCodeService,
    private notificationService: NotificationService,
    private dialogService: DialogService,
    private dialog: MatDialog,
    private translateService: TranslateService,
    private slicePipe: SlicePipe,
    private errorHandlerService: ErrorHandlerService,
    private cdRef: ChangeDetectorRef) {
    this.GetAddressBookList(this.pageIndex, this.pageSize);

  }

  public ngOnInit() {
    this.dtOptions = SiteConfig.tableConfig;
    this.updatePaginateConfig();
  }

  private updatePaginateConfig() {
    this.paginate = {
      totalItems: this.totalItems,
      currentPage: this.tablePageIndex,
      itemsPerPage: this.tablePageSize,
    };
  }

  GetAddressBookList(index: number, pagesize: number) {
    this.tablePageIndex = index;
    this.tablePageSize = pagesize;

    this.isDataLoading = true;
    if (this.subscriptionObj) {
      this.subscriptionObj.unsubscribe();
      this.subscriptionObj = null;
    }

    this.subscriptionObj = this.addressbookservice.getAddressBookPage(index.toString(), pagesize.toString())
      .subscribe(
        (contacts) => {
          if (contacts) {
            this.addressList = contacts || [];

            if (contacts && contacts.length) {
              this.totalItems = contacts[0].TotalContacts;
            }

            this.dtTrigger.next(null);
            this.updatePaginateConfig();
            this.isDataLoading = false;
          }
        },
        (err) => {
          this.dtTrigger.next(null);
          this.notificationService.notify(
            this.errorHandlerService.getHttpErrorMessage(err),
            this.translateService.instant('errorLoadingAddressList'),
            NotificationType.ERROR);
          this.isDataLoading = false;
        });
  }

  //  addAddressBook() {
  //   this.Edit = false;
  //   this.addressBook = new AddressBook();
  //   this.addressBook.Country = 'US';
  // }

  addAddressBook() {
    const dialogConfig: MatDialogConfig = {
      disableClose: true,
      width: '80%',
      data: {
        editMode: false,
      },
      maxWidth: '100%',
      panelClass: ['mobile-fullscreen-dialog'],
    };
    this.openAddressBook(dialogConfig);
  }

  editAddress(contactId: string) {
    const dialogConfig: MatDialogConfig = {
      disableClose: true,
      width: '80%',
      data: {
        editMode: true,
        contactId,
      },
      maxWidth: '100%',
      panelClass: ['mobile-fullscreen-dialog'],
    };
    this.openAddressBook(dialogConfig);
  }

  openAddressBook(dialogConfig: MatDialogConfig) {
    let dialogRef: MatDialogRef<ManageAddressComponent>;
    dialogRef = this.dialog.open(ManageAddressComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((result) => {
      if (result.result == 'success') {
        this.refreshGrid();
      }
    });
  }

  ShowMore() {
    this.pageIndex = this.pageIndex + 1;
    this.GetAddressBookList(this.pageIndex, this.pageSize);
  }

  refreshGrid() {
    this.GetAddressBookList(this.tablePageIndex, (this.pageSize * this.pageIndex));
  }

  confirmDeleteAddress(contactId: string) {
    this.dialogService.confirm(this.translateService.instant('removeAddress'), this.translateService.instant('DeleteAddressWarning'))
      .subscribe(
        (isConfirmed) => {
          if (!isConfirmed) {
            return;
          }
          this.removeAddress(contactId);
        });
  }

  removeAddress(contactId: string) {
    this.addressbookservice.removeAddress(contactId)
      .subscribe(
        (response) => {
          this.notificationService.notify('', this.translateService.instant('AddressDeletedInfo'), NotificationType.SUCCESS);
          this.refreshGrid(); // Refresh Grid to get the updated data list
        },
        (err) => {
          this.notificationService.notify(
            this.errorHandlerService.getHttpErrorMessage(err),
            this.translateService.instant('errorRemovingAddress'),
            NotificationType.ERROR);
        })
  }


  ChangeExpress(contactId, isExpress) {
    this.addressbookservice.expressAddressBook(contactId, !isExpress)
      .subscribe(
        (response) => {
          this.notificationService.notify('', this.translateService.instant('ExpressUpdateInfo'), NotificationType.SUCCESS);
          let index = this.addressList.findIndex(x => x.ContactId == contactId);          
          this.addressList[index].IsExpress = !isExpress;          
          // this.refreshGrid(); // Refresh Grid to get the updated data list
        },
        (err) => {
          this.notificationService.notify(
            this.errorHandlerService.getHttpErrorMessage(err),
            this.translateService.instant('errorChangingExpress'),
            NotificationType.ERROR);
        });
  }

  ChangeShared(contactId, isShared) {
    this.addressbookservice.shareAddressBook(contactId, !isShared).subscribe(
      (response) => {
        this.notificationService.notify('', this.translateService.instant('sharedStatusUpdated'), NotificationType.SUCCESS);
        this.refreshGrid(); // Refresh Grid to get the updated data list
      },
      (err) => {
        this.notificationService.notify(
          this.errorHandlerService.getHttpErrorMessage(err),
          this.translateService.instant('errorSharingAddress'),
          NotificationType.ERROR);
      });
  }

  searchAddressBook(searchText) {
    this.addressbookservice.searchAddressBooks(searchText).subscribe(
      (contacts) => {

        if (contacts) {
          this.addressList = contacts;
        }

        if (!searchText || searchText.trim() === '') {
          this.addressList = this.addressBooks;
        }
      },
      (err) => {
        this.notificationService.notify(
          this.errorHandlerService.getHttpErrorMessage(err),
          this.translateService.instant('errorSearchingAddress'),
          NotificationType.ERROR);
      })

  }

  // ToDo: New endpoint for full-text search Address book
  searchCarrierAddressBook(query?, carrier?, column?) {
    this.noRecordsFound = false;
    if (!query || query.trim() === '') {
      // this.GetAddressBookList(this.pageIndex, this.pageSize);
      this.refreshGrid();
      return;
    }

    if (this.subscriptionObj) {
      this.subscriptionObj.unsubscribe();
      this.subscriptionObj = null;
    }

    this.subscriptionObj = this.addressbookservice.searchCarrierAddressBook(0, query, '5').subscribe(
      (contacts) => {
        if (contacts) {
          this.addressList = contacts;
        }

        // [MV3-1654] The API for searching the address book does not support pagination.
        // Therefore, we make the pagination show everything on page 1.
        this.tablePageIndex = 1;
        this.tablePageSize = contacts.length;
        this.totalItems = contacts.length;

        this.dtTrigger.next(null);
        this.updatePaginateConfig();
        this.isDataLoading = false;
        // this.cdRef.detectChanges();
      },
      (err) => {
        this.dtTrigger.next(null);
        this.notificationService.notify(
          this.errorHandlerService.getHttpErrorMessage(err),
          this.translateService.instant('errorSearchingCarrierAddress'),
          NotificationType.ERROR);
        this.isDataLoading = false;
      });
  }

  public onPageChanged(pageNumber: number) {
    this.GetAddressBookList(pageNumber, this.pageSize);
  }


  uploadAddressBook(event) {
    const files = event.target.files;
    if (!files || !files.length) {
      return;
    }

    const file: File = files[0];
    if (!this.validateFile(file)) {
      return;
    }

    this.readUploadedAddressBook(file);
    this.importFile.nativeElement.value = '';
  }

  private postAddressBook() {
    this.addressbookservice.uploadAddressBook(this.importAddress).subscribe(
      (response) => {
        this.notificationService.notify(this.translateService.instant('addressBookUploaded'), this.translateService.instant('uploadSuccess'), NotificationType.SUCCESS);
        this.refreshGrid();
      },
      (error) => {
        this.notificationService.notify(
          this.errorHandlerService.getHttpErrorMessage(error),
          this.translateService.instant('uploadFailed'),
          NotificationType.ERROR);
      });
  }

  readUploadedAddressBook(file: File) {
    const reader: FileReader = new FileReader();

    reader.onload = (e: any) => { 
      /* read workbook */
      const bstr: string | ArrayBuffer = reader.result ; // [MV3-4041] - readAsBinaryString() deprecated & not working in IE
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'array' }); // Type changed to make [MV3-4041] change work

      /* grab first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* fetch data from sheet */
      const data: any[] = <AOA>(XLSX.utils.sheet_to_json(ws, { header: 1 }));
      data.shift();

      if (data.length >= 1) {
        this.importAddress = [];
        for (const i in data) {
          const dataarray = data[i];

          this.importAddress.push({
            Nickname: this.slicePipe.transform(dataarray[0], 0, 39),
            CompanyName: this.slicePipe.transform(dataarray[1], 0, 39),
            Firstname: this.slicePipe.transform(dataarray[2], 0, 14),
            Lastname: this.slicePipe.transform(dataarray[3], 0, 19),
            Streetaddress: dataarray[4],
            Apartmentsuite: dataarray[5],
            City: dataarray[6],
            State: dataarray[7],
            Zip: dataarray[8],
            Country: dataarray[9],
            Email: dataarray[10],
            Phone: dataarray[11],
            Fax: dataarray[12],
            Residential: dataarray[13] == '1' ? true : false,
          });
        }
      }
      this.postAddressBook();
    };
    reader.readAsArrayBuffer(file); //readAsBinaryString(file); [MV3-4041] - readAsBinaryString() deprecated & not working in IE
  }

  validateFile(file: File): boolean {
    let fileExtension = file.name.split('.').pop();
    if (!this.isInArray(this.allowedFileTypes, fileExtension)) {
      this.notificationService.notify(this.translateService.instant('fileFormatNotSupported'), this.translateService.instant('invalidFile'), NotificationType.ERROR);
      this.importFile.nativeElement.value = '';
      return false;
    }

    return true;
  }

  isInArray(array, word) {
    return array.indexOf(word.toLowerCase()) > -1;
  }
}
