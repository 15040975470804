import { Injectable } from '@angular/core';
import SafeUrlAssembler from 'safe-url-assembler';
import { HttpClientService } from '../../core/services/http/http-client.service';
import { SupplyOrder } from '../models/supply-order';
import { ApiEndpointService } from '../../core/services/api-endpoint.service';

@Injectable()
export class SupplyOrderService {

  constructor(private httpClientService: HttpClientService, private apiEndpointService: ApiEndpointService) {

  }

  /**
   * Sends a supply order.
   *
   * @param {string} contactAddress
   * @param {string} supplyOrder
   * @param {string} specialInstruction
   * @returns {Observable<Response>}
   */
  public sendSupplyOrder(data: SupplyOrder) {

    const endpoint = SafeUrlAssembler()
      .template(this.apiEndpointService.getEndpoint('sendSupplyOrder'))
      .toString();
    return this.httpClientService.post<any>(endpoint, data);
  }

  /**
   * Confirms supply order.
   *
   * @param {string} name
   * @param {string} email
   * @returns {Observable<Response>}
   */
  public confirmSupplyOrder(name: string, email: string) {
    const data = {
      name,
      email,
    };

    const endpoint = SafeUrlAssembler()
      .template(this.apiEndpointService.getEndpoint('confirmSupplyOrder'))
      .toString();
    return this.httpClientService.post<any>(endpoint, data);
  }


  /**
   * Gets supply order history.
   *
   * @param {number} pageIndex
   * @param {number} pageSize
   * @returns {Observable<Response>}
   */
  public getSupplyOrderHistory(pageIndex: number, pageSize: number) {
    const params = {};

    if (pageIndex)
      params['page'] = pageIndex;
    if (pageSize)
      params['per_page'] = pageSize;

    const endpoint = SafeUrlAssembler()
      .template(this.apiEndpointService.getEndpoint('getSupplyOrderHistory'))
      .query(params)
      .toString();

    return this.httpClientService.get<any>(endpoint);
  }

  public getSupplyOrderDetails(requestId: string) {

    const endpoint = SafeUrlAssembler()
      .template(this.apiEndpointService.getEndpoint('getSupplyOrderDetails'))
      .param('RequestId', requestId)
      .toString();

    return this.httpClientService.get<any>(endpoint);
  }

}
