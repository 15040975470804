import { Injectable } from "@angular/core";
@Injectable()
export class SupplyOrder {
    ExpressSmallBox: string = "0";
    ExpressMeduimBox: string = "0";
    ExpressLargeBox: string = "0";
    ExpressEnvelopes: string = "0";
    ExpressPaddedPak: string = "0";
    ExpressPeelStickLabel: string = "0";
    ThermalPrinterRoll: string = "0";
    Pouch: string = "0";
    InternationalPouch: string = "0";
    FedexSmallBox: string = "0";
    FedexMediumBox: string = "0";
    FedexLargeBox: string = "0";
    FedexSmallPak: string = "0";
    FedexLargePak: string = "0";
    FedexLegalSizeEnvelope: string = "0";
    FedexLaserLabel: string = "0";
    FedExDomesticAirbillPouch: string = "0";
    FedexInternationalAirbillPouch: string = "0";
    FedexEnvelopePouch: string = "0";
    FedexEnvelopeNoPouch: string = "0";
    FedExInternationalAirWayBill: string = "0";
    FedexPaddedPak: string = "0";
    FedexSturdyPak: string = "0";
    FedexTube: string = "0";
    UserEmail: string;
    UserID: number;
    SpecialInstructions: string;
    CustomerID: string;
    RequestID: string;
    CompanyName: string;
    FirstName: string;
    LastName: string;
    StreetAddress: string;
    ApartmentSuite: string;
    City: string;
    State: string;
    Country: string;
    Zip: string;
    TelNum: string;
    FaxNo:string;
}
