<div class="manage-address-dialog ups-dialog">
    <div class="dialog-header">
        <h2 *ngIf="editMode" mat-dialog-title>{{'EditAddress'|translate}}</h2>
        <h2 *ngIf="!editMode" mat-dialog-title>{{'AddNewAddress'|translate}}</h2>
        <button class="icon-button close-button" mat-dialog-close mat-raised-button type="button">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <mat-dialog-content>
        <ng-container [ngTemplateOutlet]="form"></ng-container>
    </mat-dialog-content>
</div>
<ng-template #form>
    <form [formGroup]="formGroup" class="ups-form">
        <div class="form-body">
            <mat-form-field>
                <mat-label>{{'NickName'|translate}}</mat-label>
                <input autocomplete="off"
                       formControlName="nickName"
                       matInput
                       maxlength="35"
                       required
                       type="text">
                <mat-error *ngIf="formGroup.controls.nickName?.errors">
                    <span *ngIf="formGroup.controls.nickName?.errors?.required">
                        {{'NickNameRequired'|translate}}
                    </span>
                </mat-error>
            </mat-form-field>
            <mat-form-field>
                <mat-label>{{'CompanyName'|translate}}</mat-label>
                <input (input)="onValueChanges()"
                       [required]="(!formGroup.controls.firstName.value && !formGroup.controls.lastName.value) ? true : null"
                       autocomplete="off"
                       formControlName="companyName"
                       matInput
                       maxlength="35"
                       type="text">
                <mat-error *ngIf="formGroup.controls.companyName?.errors">
                    <span *ngIf="formGroup.controls.companyName?.errors?.required">
                        {{'CompanyRequired'|translate}}
                    </span>
                </mat-error>
            </mat-form-field>
            <mat-form-field>
                <mat-label>{{'FirstName'|translate}}</mat-label>
                <input (input)="onValueChanges()"
                       [required]="!formGroup.controls.companyName.value ? true : null"
                       autocomplete="off"
                       formControlName="firstName"
                       matInput
                       maxlength="15"
                       type="text">
                <mat-error *ngIf="formGroup.controls.firstName?.errors">
                    <span *ngIf="formGroup.controls.firstName?.errors?.required">
                        {{'FirstNameRequired'|translate}}
                    </span>
                </mat-error>
            </mat-form-field>
            <mat-form-field>
                <mat-label>{{'LastName'|translate}}</mat-label>
                <input (input)="onValueChanges()"
                       [required]="!formGroup.controls.companyName.value ? true : null"
                       autocomplete="off"
                       formControlName="lastName"
                       matInput
                       maxlength="20"
                       type="text">
                <mat-error *ngIf="formGroup.controls.lastName?.errors">
                    <span *ngIf="formGroup.controls.lastName?.errors?.required">
                        {{'LastNameRequired'|translate}}
                    </span>
                </mat-error>
            </mat-form-field>
            <mat-form-field>
                <mat-label>{{'EmailAddress'|translate}}</mat-label>
                <input autocomplete="off"
                       formControlName="email"
                       matInput
                       maxlength="50"
                       type="email">
                <mat-error *ngIf="formGroup.controls.email?.errors">
                    <span *ngIf="formGroup.controls.email?.errors">
                        {{'EmailAddressInvalidFormat'|translate}}
                    </span>
                </mat-error>
            </mat-form-field>
            <mat-form-field>
                <mat-label>{{'Phone'|translate}}</mat-label>
                <input autocomplete="off"
                       formControlName="telephoneNo"
                       matInput
                       maxlength="15"
                       pattern="\d*"
                       required
                       type="text">
                <mat-error *ngIf="formGroup.controls.telephoneNo?.errors">
                    <span *ngIf="formGroup.controls.telephoneNo?.errors?.required">
                        {{'TelephoneNumberRequired'|translate}}
                    </span>
                    <span *ngIf="formGroup.controls.telephoneNo?.errors?.pattern">
                        {{'OnlyInteger'|translate: { field: 'Telephone No' } }}
                    </span>
                    <span *ngIf="!formGroup.controls.telephoneNo?.errors?.pattern && formGroup.controls.telephoneNo?.errors?.minlength">
                        {{ 'Phone Number should be at least 10 digits' | translate }}
                    </span>
                </mat-error>
            </mat-form-field>
            <mat-form-field>
                <mat-label>{{'Fax'|translate}}</mat-label>
                <input autocomplete="off"
                       formControlName="faxNo"
                       matInput
                       maxlength="15"
                       pattern="\d*"
                       type="text">
            </mat-form-field>
            <mat-form-field>
                <mat-label>{{'StreetAddress'|translate}}</mat-label>
                <input autocomplete="off"
                       formControlName="streetAddress"
                       matInput
                       maxlength="35"
                       required
                       type="text">
                <mat-error *ngIf="formGroup.controls.streetAddress?.errors">
                    <span *ngIf="formGroup.controls.streetAddress?.errors?.required">
                        {{'StreetAddressRequired'|translate}}
                    </span>
                </mat-error>
            </mat-form-field>
            <mat-form-field>
                <mat-label>{{'SuiteOrApartment'|translate}}</mat-label>
                <input autocomplete="off"
                       formControlName="apartmentSuite"
                       matInput
                       maxlength="35"
                       type="text">
            </mat-form-field>
            <mat-form-field>
                <mat-select (selectionChange)="onCountryChange($event.value)" formControlName="country"
                            required>
                    <mat-option *ngFor="let country of countriesList" value="{{country.CountryCode}}">
                        {{country.CountryName}}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="formGroup.controls.country?.errors">
                    <span *ngIf="formGroup.controls.country?.errors?.required">
                        {{'SelectACountry'|translate}}
                    </span>
                </mat-error>
            </mat-form-field>
            <mat-form-field>
                <mat-label>{{'PostalCode'|translate}}</mat-label>
                <input [required]="isZipMandatory===true?true:null"
                       autocomplete="off"
                       formControlName="zip"
                       matInput
                       maxlength="15"
                       name="zip"
                       pattern="[a-zA-Z0-9]+"
                       type="text">
                <mat-error *ngIf="formGroup.controls.zip?.errors">
                    <span *ngIf="formGroup.controls.zip?.errors?.required">
                        {{'ZipCodeRequired'|translate}}
                    </span>
                </mat-error>
            </mat-form-field>
            <mat-form-field>
                <mat-label>{{'City'|translate}}</mat-label>
                <input autocomplete="off"
                       formControlName="city"
                       matInput
                       maxlength="29"
                       required
                       type="text">
                <mat-error *ngIf="formGroup.controls.city?.errors">
                    <span *ngIf="formGroup.controls.city?.errors?.required">
                        {{'CityRequired'|translate}}
                    </span>
                </mat-error>
            </mat-form-field>
            <mat-form-field>
                <mat-label>{{'StateOrProvince'|translate}}</mat-label>
                <input [required]="isStateProvince === true ? true : null"
                       autocomplete="off"
                       formControlName="state"
                       matInput
                       maxlength="35"
                       type="text">
                <mat-error *ngIf="formGroup.controls.state?.errors">
                    <span *ngIf="formGroup.controls.state?.errors?.required">
                        {{'StateProvinceRequired'|translate}}
                    </span>
                </mat-error>
            </mat-form-field>
            <div class="checkboxes">
                <mat-checkbox formControlName="isResidential">
                    {{'ResidentialAddress'|translate}}
                </mat-checkbox>
                <mat-checkbox formControlName="isExpress">
                    {{'IncludeinExpressContact'|translate}}
                </mat-checkbox>
            </div>
        </div>
        <div class="form-footer">
            <div class="form-actions">
                <button (click)="updateAddressBook()"
                        *ngIf="editMode"
                        [disabled]="!formGroup.valid"
                        class="form-action"
                        mat-raised-button>
                    {{'Update'|translate}}
                </button>
                <button (click)="saveAddressBook()"
                        *ngIf="!editMode"
                        [disabled]="!formGroup.valid"
                        class="form-action"
                        mat-raised-button>
                    {{'Save'|translate}}
                </button>
            </div>
        </div>
    </form>
</ng-template>
