import { AfterContentChecked, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { PaginationInstance } from 'ngx-pagination';
import { TranslateService } from '@ngx-translate/core';
import { SiteConfig } from '../../../../config/site-config';
import { NotificationService } from '../../../shared/services/notification/notification.service';
import { NotificationType } from '../../../shared/models/notification-type';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { SupplyOrderService } from '../../services/supply-order.service';
import { ErrorHandlerService } from '../../../shared/services/error-handler/error-handler.service';
import { SpinnerService } from '../../../core/services/spinner/spinner.service';
import { OrderDetailComponent } from '../order-detail/order-detail.component';

@Component({
    selector: 'upsc-order-supplies-history',
    templateUrl: './order-supplies-history.component.html',
    styleUrls: ['./order-supplies-history.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class OrderSuppliesHistoryComponent implements OnInit{

    public dtOptions: any = {};
    public dtTrigger: Subject<any> = new Subject<any>();

    private currentPage = 1;
    private pageSize = 10;
    private totalItems = 0;
   
    public paginate: PaginationInstance;    
    public orders: any[];

    constructor(private router: Router,
        private spinnerService: SpinnerService,
        private notificationService: NotificationService,
        private errorHandlerService: ErrorHandlerService,
        private translateService: TranslateService,
        private dialog: MatDialog,
        private supplyOrderService: SupplyOrderService,private cdRef : ChangeDetectorRef) {
            this.getSupplyOrderHistory(this.currentPage, this.pageSize);
    }
    
    ngOnInit() { 
       
        this.dtOptions = SiteConfig.tableConfig;   
        
        this.updatePaginateConfig();    
        this.cdRef.detectChanges();      
    }
   
    private updatePaginateConfig() {
        this.paginate = {
          
            totalItems: this.totalItems,
            currentPage: this.currentPage,
            itemsPerPage: this.pageSize,
        };
    }


    getSupplyOrderHistory(pageNumber: number, pageSize: number) {
        this.spinnerService.show();

        this.supplyOrderService.getSupplyOrderHistory(pageNumber, pageSize).subscribe(
            (orders) => {
                this.spinnerService.hide();
                this.orders = orders;               
                if (this.orders.length > 0) {
                    this.totalItems = orders[0].TotalOrderHistory;
                    this.currentPage = pageNumber;
                    this.pageSize = pageSize;                                    
                }
                
                this.dtTrigger.next(null);
                this.updatePaginateConfig();
            },
            (error) => {
                this.spinnerService.hide();
                this.dtTrigger.next(null);
                this.notificationService.notify(
                    this.errorHandlerService.getHttpErrorMessage(error),
                    this.translateService.instant('supplyOrder.failedGettingOrderHistory'),
                    NotificationType.ERROR);
            });
    }

    public onPageChanged(pageNumber: number) {
        this.getSupplyOrderHistory(pageNumber, this.pageSize);
    }

    openOrderDetail(requestId) {

        const dialogConfig: MatDialogConfig = {
            disableClose: true,
            width: '35em',
            data: {
                requestId: requestId
            },
        };

        const dialogRef: MatDialogRef<OrderDetailComponent> = this.dialog.open(OrderDetailComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(
            (result) => { });
    }

    goBack() {
        this.router.navigate(['/support/supply-order']);
    }

}
