import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { TranslateModule } from '@ngx-translate/core';
import { AddressBookModule } from '../address-book/address-book.module';
import { LayoutModule } from '../layout/layout.module';
import { S3FileDownloaderModule } from '../shared/components/s3-file-downloader/s3-file-downloader.module';
import { SharedModule } from '../shared/shared.module';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { ContactUs } from './models/contact-us.model';
import { SupplyOrder } from './models/supply-order';
import { OrderItemUnitPipe } from './pipes/order-item-unit.pipe';
import { RestrictedZipCodesComponent } from './restricted-zip-codes/restricted-zip-codes.component';
import { SupplyOrderService } from './services/supply-order.service';
import { OrderDetailComponent } from './supply-order/order-detail/order-detail.component';
import { OrderSuppliesHistoryComponent } from './supply-order/order-supplies-history/order-supplies-history.component';
import {
    SupplyOrderEditEmailComponent,
} from './supply-order/supply-order-edit-email/supply-order-edit-email.component';
import { SupplyOrderComponent } from './supply-order/supply-order.component';
import { SupportDocumentsComponent } from './support-documents/support-documents.component';
import { SupportRoutingModule } from './support-routing.module';

@NgModule({
    imports: [
        SupportRoutingModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        SharedModule,
        AddressBookModule,
        TranslateModule,
        LayoutModule,
        S3FileDownloaderModule,
    ],
    declarations: [
        SupplyOrderComponent,
        ContactUsComponent,
        SupplyOrderEditEmailComponent,
        RestrictedZipCodesComponent,
        SupportDocumentsComponent,
        OrderSuppliesHistoryComponent,
        OrderDetailComponent,
        OrderItemUnitPipe,
    ],
    providers: [
        SupplyOrderService,
        SupplyOrder,
        ContactUs,
        MatStepper,
    ],
    exports: [
        OrderItemUnitPipe,
    ],
})
export class SupportModule {
}
