<div class="contact-us">
    <div class="ups-page-header">
        <h1 class="page-title">{{ 'ContactUs'|translate }}</h1>
    </div>

    <div class="locations">
        <!-- Swap the EU locations on the top when EU users logged in -->
        <div *ngIf="!isEUUser()" class="location us">
            <h2 class="location-title">{{ 'USLocations' | translate }}</h2>
            <ng-container [ngTemplateOutlet]="contactsTemplate"
                          [ngTemplateOutletContext]="{contacts: USContacts}"></ng-container>
        </div>

        <div class="location international">
            <h2 class="location-title">{{ 'InternationalLocations' | translate }}</h2>
            <ng-container [ngTemplateOutlet]="contactsTemplate"
                          [ngTemplateOutletContext]="{contacts: internationalEUContacts}"></ng-container>
            <div *ngIf="this.user.CustomerId.substr(0, 2) === 'GB'"> {{ 'ProofOfInsuranceMessageGB' | translate}}</div>
            <div *ngIf="this.user.CustomerId.substr(0, 2) === 'DE'"> {{ 'ProofOfInsuranceMessageDE' | translate}}</div>
            <div *ngIf="this.user.CustomerId.substr(0, 2) === 'FR'"> {{ 'ProofOfInsuranceMessageFR' | translate}}</div>
            <div *ngIf="this.user.CustomerId.substr(0, 2) === 'IT'"> {{ 'ProofOfInsuranceMessageIT' | translate}}</div>
            <ng-container [ngTemplateOutlet]="contactsTemplate"
                          [ngTemplateOutletContext]="{contacts: internationalContacts}"></ng-container>
        </div>

        <div *ngIf="isEUUser()" class="location us">
            <h2 class="location-title">{{ 'USLocations' | translate }}</h2>
            <ng-container [ngTemplateOutlet]="contactsTemplate"
                          [ngTemplateOutletContext]="{contacts: USContacts}"></ng-container>
        </div>
    </div>
   
    <div *ngIf="isEUUser()">
        <div  style="font-weight: bold;padding-left: 1em;">{{'complaints.title'|translate}}:  <a href="#"(click)="openComplaintsDialog($event)" class="link">
            {{'learnMore'|translate}}</a></div> 
    </div>
</div>

<ng-template #contactsTemplate let-contactItems="contacts">
    <div class="contacts">
        <div class="contact" *ngFor="let contact of contactItems">
            <h2 class="contact-title">{{ contact.ContactTitle }}</h2>
            <div class="address-lines">
                <div class="address-line" *ngIf="contact.AddressLine1!=''">{{contact.AddressLine1}}</div>
                <div class="address-line" *ngIf="contact.AddressLine2!=''">{{contact.AddressLine2}}</div>
                <div class="address-line" *ngIf="contact.AddressLine3!=''">{{contact.AddressLine3}}</div>
                <div class="address-line" *ngIf="contact.AddressLine4!=''">{{contact.AddressLine4}}</div>
            </div>
            <div class="deprecated-key-values">
                <div class="key-value">
                    <div class="key">{{'TelwithColon' | translate}}</div>
                    <div class="value">{{contact.Tel}}</div>
                </div>
            </div>
            <div *ngIf= "contact.Fax != '' "class="deprecated-key-values">
                <div class="key-value">
                    <div class="key">{{'FaxwithColon' | translate}}</div>
                    <div class="value">{{contact.Fax}}</div>
                </div>
            </div>
            <div class="deprecated-key-values">
                <div class="key-value">
                    <div class="key">{{'EmailwithColon'|translate}}</div>
                    <div class="value">
                        <a href="mailto:{{contact.Email}}">{{contact.Email}}</a>
                    </div>
                </div>
            </div>
            <div class="deprecated-key-values"
            *ngIf="contact.ContactTitle != ('Parcel Pro Japan: Tokyo Head Office') && contact.ContactTitle != ('Parcel Pro Japan: Kansai (Kobe) Sales Office') && contact.ContactTitle != ('Parcel Pro Hong Kong LTD' )">
                <div class="key-value">
                    <div class="key">{{'HourswithColon'|translate}}</div>
                    <div class="value" [innerHtml]="contact.Hours"></div>
                </div>
            </div>            
        </div>
    </div>
</ng-template>
