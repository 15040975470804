<div class="ups-dialog">
    <div class="dialog-header">
        <h2 mat-dialog-title>{{ 'supplyOrder.orderDetail' | translate }}</h2>
        <mat-dialog-actions>
        </mat-dialog-actions>
        <button type="button" mat-raised-button class="icon-button close-button" mat-dialog-close>
            <mat-icon>close</mat-icon>
        </button>
    </div>

    <mat-dialog-content>
        <ng-container [ngTemplateOutlet]="form"></ng-container>
    </mat-dialog-content>
</div>
<ng-template #form>
    <div class="order-details">
        <div class="order-detail instruction">
                {{ 'supplyOrder.orderDetailInstruction' | translate }}
        </div>
        <div class="order-detail order-date">
            <h3>
                <strong>{{ 'supplyOrder.orderDate' | translate }}</strong>
            </h3>
            <div> {{ supplyOrderDetail?.RequestDate | date : 'MM/dd/yyyy' }} </div>
        </div>
        <div class="order-detail order-number">
            <h3>
                <strong>Order Number</strong>
            </h3>
            <div> {{ supplyOrderDetail?.OrderNumber }} </div>
        </div>
        <div class="order-detail shipping-address">
            <h3>
                <strong>{{ 'supplyOrder.shippingAddress' | translate }}</strong>
            </h3>
            <div class="deprecated-key-values">
                <div class="key-value">
                    <div class="key">
                        {{ 'CompanyName' | translate }}
                    </div>
                    <div class="value">
                        {{ supplyOrderDetail?.CompanyName }}
                    </div>
                </div>
                <div class="key-value">
                    <div class="key">
                        {{ 'StreetAddress' | translate }}
                    </div>
                    <div class="value">
                        {{ supplyOrderDetail?.StreetAddress }}
                    </div>
                </div>
                <div class="key-value">
                    <div class="key">
                        {{'City'|translate}}, {{'State'|translate}}
                    </div>
                    <div class="value">
                        {{ supplyOrderDetail?.City }}, {{ supplyOrderDetail?.State }}
                    </div>
                </div>
                <div class="key-value">
                    <div class="key">
                        {{'Zip'|translate}}, {{'onlyCountry'|translate}}
                    </div>
                    <div class="value">
                        {{ supplyOrderDetail?.Zip }}, {{ supplyOrderDetail?.Country }}
                    </div>
                </div>
                <div class="key-value">
                    <div class="key">
                        {{'PhoneNumber'|translate}}
                    </div>
                    <div class="value">
                        {{ supplyOrderDetail?.TelNum }}
                    </div>
                </div>
            </div>
        </div>
        <div class="order-detail order-items">
            <div class=" table-wrapper" [class.responsive-table]="dtOptions.responsive">
                <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="stripe" [hidden]="!orderItems" >
                    <thead> 
                        <tr>                            
                            <th>{{'supplyOrder.item'|translate}}</th>
                            <th>{{'Quantity'|translate}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of orderItems">                              
                            <td>
                                {{ ('orderDetail.' + item.name) | translate }}
                            </td>
                            <td>
                                {{ item | orderItemUnit }}
                            </td>
                        </tr>                       
                    </tbody>
                </table>
            </div>
        </div>
        <div class="order-detail total">{{'TotalItems'|translate}}:   {{ totalItems }} </div>
    </div>

</ng-template>
