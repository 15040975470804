import { ContactType } from '../../shared/enum/general-enum';
import { Injectable } from "@angular/core";
@Injectable()
export class AddressBook {
    ApartmentSuite: string = '';
    City: string = '';
    CompanyName: string = '';
    ContactId: string = '';
    Country: string = '';
    CustomerId: string = '';
    ContactType: ContactType = ContactType.AddressBook;
    Email: string = '';
    FaxNo: string = '';
    FirstName: string = '';
    IsExpress: boolean = false;
    IsResidential: boolean = false;
    IsUserDefault: boolean = false;
    UPSPickUpType: string = '0';
    LastName: string = '';
    NickName: string = '';
    State: string = '';
    StreetAddress: string = '';
    TelephoneNo: string = '';
    Zip: string = '';
    ProvinceRegion: string = '';
}

