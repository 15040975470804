import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { TranslateService } from '@ngx-translate/core';
import { AddressBookService } from '../../address-book/services/address-book.service';
import { MailAddressService } from '../../shared/services/mail-address/mail-address.service';
import { MailAddress } from '../../shared/models/mail-address.model';
import { SupplyOrderService } from '../services/supply-order.service';
import { SupplyOrder } from '../models/supply-order';
import { ZipCodeService } from '../../shared/services/zip-code/zip-code.service';
import { NotificationService } from '../../shared/services/notification/notification.service';
import { NotificationType } from '../../shared/models/notification-type';
import { SupplyOrderEditEmailComponent } from './supply-order-edit-email/supply-order-edit-email.component';
import { SpinnerService } from '../../core/services/spinner/spinner.service';
import { UtilityService } from '../../shared/services/utility/utility.service';
import { ErrorHandlerService } from '../../shared/services/error-handler/error-handler.service';
import { OrderDetailComponent } from './order-detail/order-detail.component';
import * as _ from 'lodash';

@Component({
  selector: 'upsc-supply-order',
  templateUrl: './supply-order.component.html',
  styleUrls: ['./supply-order.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SupplyOrderComponent implements OnInit {
  public formGroup: UntypedFormGroup;
  public supplyOrderFormGroup: UntypedFormGroup;
  public stepOneFormGroup: UntypedFormGroup;
  public stepTwoFormGroup: UntypedFormGroup;

  public totalOrderItems = 0;
  public countriesList: any[];
  public statesList: any[];
  public specialInstructions: string;
  public pickSupply = true;
  public confirmMailingAddress = false;
  public orderComplete = false;
  //public customMessage: string;
  //public showAlert = false;

  private currentPage = 1;
  private pageSize = 5;
  public recentOrder: any;

  public upsPouchDomesticOptions: number[];
  public upsPouchInternationalOptions: number[];
  public upsExpressSmallBoxOptions: number[];
  public upsExpressMediumBoxOptions: number[];
  public upsExpressLargeBoxOptions: number[];
  public upsExpressEnvelopOptions: number[];
  public upsExpressPaddedPakOptions: number[];
  public upsLabelPeelAndStickOptions: number[];
  public upsLabelThermalPrinterRollOptions: number[];

  public fedexPouchDomesticOptions: number[];
  public fedexPouchInternationalOptions: number[];
  public fedexBoxSmallOptions: number[];
  public fedexBoxMediumOptions: number[];
  public fedexBoxLargeOptions: number[];
  public fedexPakSmallOptions: number[];
  public fedexPakLargeOptions: number[];
  public fedexLabelInternationalOptions: number[];
  public fedexLabelLaserOptions: number[];
  public fedexEnvelopeRegularOptions: number[];
  public fedexEnvelopeLegalOptions: number[];

  public isSupplyReady = false;
  public isOrderSuccess = false;
  public showRecentOrder = false;  

  @ViewChild('stepper', { static: true }) public stepper: MatStepper;
  //@ViewChild('invalidOrderSupply') invalidOrderSupply: ElementRef;
  @ViewChild('mailAddressForm') mailAddressForm: any;
  @ViewChild('close') close: ElementRef;
  @ViewChild('supplyOrderForm') supplyOrderForm: any;

  constructor(private addressbookservice: AddressBookService,
    private mailAddressService: MailAddressService,
    public mailAddress: MailAddress,
    private supplyOrderService: SupplyOrderService,
    public supplyOrder: SupplyOrder,
    private zipcodeservice: ZipCodeService,
    private notificationService: NotificationService,
    private spinnerService: SpinnerService,
    private utilityService: UtilityService,
    private errorHandlerService: ErrorHandlerService,
    private translateService: TranslateService,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private dialog: MatDialog) {
    this.GetCountries();
    this.GetMailAddress();
    this.getSupplyOrderHistory(this.currentPage, this.pageSize);
  }

  public ngOnInit() {
    this.stepOneFormGroup = this.formBuilder.group({
      isSupplyReady: [false, Validators.compose([Validators.requiredTrue])],
    });

    this.stepTwoFormGroup = this.formBuilder.group({
      isOrderSuccess: [false, Validators.compose([Validators.requiredTrue])],
    });

    this.supplyOrderFormGroup = this.formBuilder.group({
      upsPouchDomestic: ['0'],
      upsPouchInternational: ['0'],
      upsExpressSmallBox: ['0'],
      upsExpressMediumBox: ['0'],
      upsExpressLargeBox: ['0'],
      upsExpressEnvelope: ['0'],
      upsExpressPaddedPak: ['0'],
      upsLabelPeelAndStick: ['0'],
      upsLabelThermalPrinterRoll: ['0'],

      fedexPouchDomestic: ['0'],
      fedexPouchInternational: ['0'],
      fedexBoxSmall: ['0'],
      fedexBoxMedium: ['0'],
      fedexBoxLarge: ['0'],
      fedexPakSmall: ['0'],
      fedexPakLarge: ['0'],
      fedexLabelInternational: ['0'],
      fedexLabelLaser: ['0'],
      fedexEnvelopeRegular: ['0'],
      fedexEnvelopeLegal: ['0'],

      specialInstructions: [''],
    });

    this.upsPouchDomesticOptions = [1, 2];
    this.upsPouchInternationalOptions = [25, 50, 75, 100];
    this.upsExpressSmallBoxOptions = [1, 2, 3, 4, 5];
    this.upsExpressMediumBoxOptions = [1, 2, 3, 4, 5];
    this.upsExpressLargeBoxOptions = [1, 2, 3, 4, 5];
    this.upsExpressEnvelopOptions = [1, 2, 3, 4];
    this.upsExpressPaddedPakOptions = [1, 2, 3, 4];
    this.upsLabelPeelAndStickOptions = [1, 2, 3, 4];
    this.upsLabelThermalPrinterRollOptions = [1, 2, 3, 4, 5];

    this.fedexPouchDomesticOptions = [50];
    this.fedexPouchInternationalOptions = [50];
    this.fedexBoxSmallOptions = [10, 20, 30, 40, 50];
    this.fedexBoxMediumOptions = [10, 20, 30, 40, 50];
    this.fedexBoxLargeOptions = [10, 20, 30, 40, 50];
    this.fedexPakSmallOptions = [25, 50];
    this.fedexPakLargeOptions = [25, 50];
    this.fedexLabelInternationalOptions = [50];
    this.fedexLabelLaserOptions = [200];
    this.fedexEnvelopeRegularOptions = [25, 50];
    this.fedexEnvelopeLegalOptions = [50];

    this.formGroup = this.formBuilder.group({
      Firstname: [''],
      Lastname: [''],
      CompanyName: [''],
      StreetAddress: [''],
      ApartmentSuite: [''],
      zipCode: [''],
      City: [''],
      State: [''],
      Country: [''],
      Phone: [''],
      Fax: [''],
      EmailAddress: [''],
    });

    this.ResetOrder();

    this.monitorValueChanges();
  }

  private monitorValueChanges() {
    this.supplyOrderFormGroup.valueChanges
      .subscribe(
        (form) => {
          this.totalOrderItems = Object.keys(form).filter(key => +form[key] > 0).length;
          this.isSupplyReady = !!this.totalOrderItems;

          this.stepOneFormGroup.controls.isSupplyReady.setValue(this.isSupplyReady);

          this.supplyOrder.ExpressSmallBox = form.upsExpressSmallBox;
          this.supplyOrder.ExpressMeduimBox = form.upsExpressMediumBox;
          this.supplyOrder.ExpressLargeBox = form.upsExpressLargeBox;

          this.supplyOrder.ExpressEnvelopes = form.upsExpressEnvelope;
          this.supplyOrder.ExpressPaddedPak = form.upsExpressPaddedPak;

          this.supplyOrder.ExpressPeelStickLabel = form.upsLabelPeelAndStick;
          this.supplyOrder.ThermalPrinterRoll = form.upsLabelThermalPrinterRoll;

          this.supplyOrder.Pouch = form.upsPouchDomestic;
          this.supplyOrder.InternationalPouch = form.upsPouchInternational;

          this.supplyOrder.FedexSmallBox = form.fedexBoxSmall;
          this.supplyOrder.FedexMediumBox = form.fedexBoxMedium;
          this.supplyOrder.FedexLargeBox = form.fedexBoxLarge;

          this.supplyOrder.FedexSmallPak = form.fedexPakSmall;
          this.supplyOrder.FedexLargePak = form.fedexPakLarge;

          this.supplyOrder.FedexEnvelopePouch = form.fedexEnvelopeRegular;
          this.supplyOrder.FedexLegalSizeEnvelope = form.fedexEnvelopeLegal;

          this.supplyOrder.FedExInternationalAirWayBill = form.fedexLabelInternational;
          this.supplyOrder.FedexLaserLabel = form.fedexLabelLaser;

          this.supplyOrder.FedExDomesticAirbillPouch = form.fedexPouchDomestic;
          this.supplyOrder.FedexInternationalAirbillPouch = form.fedexPouchInternational;

          this.supplyOrder.SpecialInstructions = form.specialInstructions;
        },
      );
  }

  public submitSupplyOrder(event, form) {
    event.preventDefault();

    this.spinnerService.show();
    this.supplyOrderService.sendSupplyOrder(this.supplyOrder).subscribe(
      (response) => {
        this.notificationService.notify('', this.translateService.instant('emailSentSuccess'), NotificationType.SUCCESS);
        this.stepTwoFormGroup.controls.isOrderSuccess.setValue(true);
        this.isOrderSuccess = true;
        this.stepper.next();
        this.spinnerService.hide();
      },
      (err) => {
        this.notificationService.notify(
          this.errorHandlerService.getHttpErrorMessage(err),
          this.translateService.instant('supplyOrder.errorSendingSupplyOrder'),
          NotificationType.ERROR);
        this.stepTwoFormGroup.controls.isOrderSuccess.setValue(false);
        this.spinnerService.hide();
      });
  }

  public restartSteps() {
    this.supplyOrderFormGroup.patchValue(
      {
        upsPouchDomestic: '0',
        upsPouchInternational: '0',
        upsExpressSmallBox: '0',
        upsExpressMediumBox: '0',
        upsExpressLargeBox: '0',
        upsExpressEnvelope: '0',
        upsExpressPaddedPak: '0',
        upsLabelPeelAndStick: '0',
        upsLabelThermalPrinterRoll: '0',

        fedexPouchDomestic: '0',
        fedexPouchInternational: '0',
        fedexBoxSmall: '0',
        fedexBoxMedium: '0',
        fedexBoxLarge: '0',
        fedexPakSmall: '0',
        fedexPakLarge: '0',
        fedexLabelInternational: '0',
        fedexLabelLaser: '0',
        fedexEnvelopeRegular: '0',
        fedexEnvelopeLegal: '0',

        specialInstructions: '',
      },
      {
        emitEvent: false,
      });

    this.stepOneFormGroup.controls.isSupplyReady.setValue(false);
    this.isSupplyReady = false;

    this.stepTwoFormGroup.controls.isOrderSuccess.setValue(false);
    this.isOrderSuccess = false;

    this.ResetOrder();

    this.spinnerService.show();
    this.utilityService.delay(
      () => {
        this.stepper.selectedIndex = 0;
        this.spinnerService.hide();
      },
      1000);
  }

  GetCountries() {
    this.addressbookservice.getCountries().subscribe(
      (countries) => {
        if (countries) {
          this.countriesList = _.sortBy(countries, ['CountryName']);
        }
      },
      (err) => {
        this.notificationService.notify(
          this.errorHandlerService.getHttpErrorMessage(err),
          this.translateService.instant('CountriesLoadingError_msg'),
          NotificationType.ERROR);
      });
  }

  GetMailAddress() {
    this.mailAddressService.getMailAddress().subscribe(mailAddress => {

      if (mailAddress && mailAddress.length > 0) {
        this.initiateMailAddress(mailAddress[0]);
      } //this.mailAddress = mailAddress[0];
    }, err => {
      this.notificationService.notify(
        this.errorHandlerService.getHttpErrorMessage(err),
        this.translateService.instant('supplyOrder.errorLoadingMailAddress'),
        NotificationType.ERROR);

    });
  }

  initiateMailAddress(mailAddress) {
    this.supplyOrder.FirstName = mailAddress.FirstName;
    this.supplyOrder.LastName = mailAddress.LastName;
    this.supplyOrder.ApartmentSuite = mailAddress.ApartmentSuite;
    this.supplyOrder.StreetAddress = mailAddress.StreetAddress;
    this.supplyOrder.Zip = mailAddress.Zip;
    this.supplyOrder.City = mailAddress.City;
    this.supplyOrder.State = mailAddress.State;
    this.supplyOrder.Country = mailAddress.Country;
    this.supplyOrder.TelNum = mailAddress.TelephoneNo;
    this.supplyOrder.FaxNo = mailAddress.FaxNo;
    this.supplyOrder.UserEmail = mailAddress.Email;
    if (this.supplyOrder.CompanyName) {
      this.supplyOrder.CompanyName = mailAddress.CompanyName;
    }
    else {
      this.supplyOrder.CompanyName = mailAddress.LastName;
    }

  }

  validateSupplyOrder() {
    let isValid = false;
    for (const control in this.supplyOrderForm.controls) {
      if (this.supplyOrderForm.controls[control].value != '0') {
        isValid = true;
      }
    }

    if (isValid) {
      this.toggleTab('confirmMailingAddress');
    } else {
      this.notificationService.notify('', this.translateService.instant('supplyOrder.selectOneItem'), NotificationType.ERROR);
      //this.invalidOrderSupply.nativeElement.click();
    }
  }

  ResetOrder() {
    this.supplyOrder.Pouch = '0';
    this.supplyOrder.ExpressSmallBox = '0';
    this.supplyOrder.ExpressMeduimBox = '0';
    this.supplyOrder.ExpressLargeBox = '0';
    this.supplyOrder.ExpressEnvelopes = '0';
    this.supplyOrder.ExpressPaddedPak = '0';
    this.supplyOrder.ExpressPeelStickLabel = '0';
    this.supplyOrder.ThermalPrinterRoll = '0';
    this.supplyOrder.Pouch = '0';
    this.supplyOrder.InternationalPouch = '0';
    this.supplyOrder.FedexSmallBox = '0';
    this.supplyOrder.FedexMediumBox = '0';
    this.supplyOrder.FedexLargeBox = '0';
    this.supplyOrder.FedexSmallPak = '0';
    this.supplyOrder.FedexLargePak = '0';
    this.supplyOrder.FedexLegalSizeEnvelope = '0';
    this.supplyOrder.FedexLaserLabel = '0';
    this.supplyOrder.FedExDomesticAirbillPouch = '0';
    this.supplyOrder.FedexInternationalAirbillPouch = '0';
    this.supplyOrder.FedexEnvelopePouch = '0';
    this.supplyOrder.FedexEnvelopeNoPouch = '0';
    this.supplyOrder.FedExInternationalAirWayBill = '0';
    this.supplyOrder.FedexPaddedPak = '0';
    this.supplyOrder.FedexSturdyPak = '0';
    this.supplyOrder.FedexTube = '0';

    this.totalOrderItems = 0;

  }

  editMailAddress() {
    this.formGroup = this.formBuilder.group({
      Firstname: [this.supplyOrder.FirstName],
      Lastname: [this.supplyOrder.LastName],
      CompanyName: [this.supplyOrder.CompanyName],
      StreetAddress: [this.supplyOrder.StreetAddress],
      ApartmentSuite: [this.supplyOrder.ApartmentSuite],
      zipCode: [this.supplyOrder.Zip],
      City: [this.supplyOrder.City],
      State: [this.supplyOrder.State],
      Country: [this.supplyOrder.Country],
      Phone: [this.supplyOrder.TelNum],
      Fax: [this.supplyOrder.FaxNo],
      EmailAddress: [this.supplyOrder.UserEmail],
    });
  }

  //gets city, state and country by zipcode
  getAddressbyZipCode(event) {
    if (!['US', 'CA', 'PR', 'VI', 'GU'].includes(this.formGroup.controls.Country.value)) {
      return;
    }

    if (this.formGroup.controls.zipCode.value && this.formGroup.controls.zipCode.value.length > 4) {
      this.zipcodeservice.getAddressByZipCode(this.formGroup.controls.zipCode.value).subscribe(response => {

        if (response) {
          this.formGroup.controls['City'].setValue(response.City);
          this.formGroup.controls['State'].setValue(response.State);
          this.formGroup.controls['Country'].setValue(response.Country);
        }
      }, error => {
      });
    }
  }

  updateMailAddress(event, form) {
    event.preventDefault();

    if (this.validateMailAddress()) {
      this.supplyOrder.FirstName = this.formGroup.controls.Firstname.value;
      this.supplyOrder.LastName = this.formGroup.controls.Lastname.value;
      this.supplyOrder.CompanyName = this.formGroup.controls.CompanyName.value
      this.supplyOrder.ApartmentSuite = this.formGroup.controls.ApartmentSuite.value
      this.supplyOrder.StreetAddress = this.formGroup.controls.StreetAddress.value
      this.supplyOrder.Zip = this.formGroup.controls.zipCode.value
      this.supplyOrder.City = this.formGroup.controls.City.value
      this.supplyOrder.State = this.formGroup.controls.State.value
      this.supplyOrder.Country = this.formGroup.controls.Country.value
      this.supplyOrder.TelNum = this.formGroup.controls.Phone.value
      this.supplyOrder.FaxNo = this.formGroup.controls.Fax.value
      this.supplyOrder.UserEmail = this.formGroup.controls.EmailAddress.value

      this.close.nativeElement.click();
    }

  }

  sendSupplyOrder() {
    this.supplyOrderService.sendSupplyOrder(this.supplyOrder).subscribe(response => {
      if (response && response.status == 200) {
        this.notificationService.notify('', this.translateService.instant('emailSentSuccess'), NotificationType.SUCCESS);
      }
      this.toggleTab('orderComplete');
      this.getSupplyOrderHistory(this.currentPage, this.pageSize); //gets & shows recent order date
    }, err => {
      this.notificationService.notify(
        this.errorHandlerService.getHttpErrorMessage(err),
        this.translateService.instant('supplyOrder.errorSendingSupplyOrder'),
        NotificationType.ERROR);
    });
  }


  toggleTab(tab: string) {
    if (tab == 'pickSupply') {
      this.pickSupply = true;
      this.confirmMailingAddress = false;
      this.orderComplete = false;
    } else if (tab == 'confirmMailingAddress') {
      this.pickSupply = false;
      this.confirmMailingAddress = true;
      this.orderComplete = false;
    } else if (tab == 'orderComplete') {
      this.pickSupply = false;
      this.confirmMailingAddress = false;
      this.orderComplete = true;
    }
  }

  supplyOrderItemChange() {
    this.totalOrderItems = 0;
    for (const control in this.supplyOrderForm.controls) {
      if (this.supplyOrderForm.controls[control].value != '0') {
        this.totalOrderItems = this.totalOrderItems + 1;
      }
    }

  }

  validateMailAddress(): boolean {
    for (const control in this.formGroup.controls) {
      if (this.formGroup.controls[control].status == 'INVALID') {

        this.notificationService.notify(this.translateService.instant('supplyOrder.pleaseEnter') + control,
          this.translateService.instant('supplyOrder.validationFailed'), NotificationType.ERROR);
        return false;
      }
    }

    return true;
  }

  public openEditEmail(event) {
    event.preventDefault();
    const dialogConfig: MatDialogConfig = {
      disableClose: true,
    };
    const dialogRef: MatDialogRef<SupplyOrderEditEmailComponent> = this.dialog.open(SupplyOrderEditEmailComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      (result) => {
        if (result && result.result == 'success') {
          this.supplyOrder = result.data;
        } else {

        }
      });
  }

  public goToHistory() {
    this.router.navigate(['/support/order-supplies-history']);
  }

  getSupplyOrderHistory(pageNumber: number, pageSize: number) {
    this.spinnerService.show();
    this.supplyOrderService.getSupplyOrderHistory(pageNumber, pageSize).subscribe(
      (orders) => {
        this.spinnerService.hide();
        if (orders && orders.length > 0) {
          this.recentOrder = orders[0];
           this.showRecentOrder = true;
        }
        else {
           this.showRecentOrder = false;
        }
      },
      (error) => {
        this.spinnerService.hide();
        this.notificationService.notify(
          this.errorHandlerService.getHttpErrorMessage(error),
          this.translateService.instant('supplyOrder.failedGettingOrderHistory'),
          NotificationType.ERROR);
      });
  }

  public openOrderDetail(requestId) {

    const dialogConfig: MatDialogConfig = {
      disableClose: true,
      width: '35em',
      data: {
        requestId: requestId
      },
    };

    const dialogRef: MatDialogRef<OrderDetailComponent> = this.dialog.open(OrderDetailComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      (result) => { });
  }

  public hideRecentOrderLink() {
    this.showRecentOrder = false;
  }
}
