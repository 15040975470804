import { Component, OnInit } from '@angular/core';
import { AppState } from '../../app.state';
import { ContactUs } from '../models/contact-us.model';
import { Observable, of } from 'rxjs';
import { User } from '../../shared/services/user/models/user.model';
import { UserService } from '../../shared/services/user/user.service';
import { SharedService } from '../../core/services/shared.service';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ComplaintsComponent } from '../../shared/components/complaints/complaints.component';

@Component({
  selector: 'upsc-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss'],
})
export class ContactUsComponent implements OnInit {
  contacts: Observable<ContactUs[]>;

  public USContacts: ContactUs[];
  public internationalContacts: ContactUs[];
  public internationalEUContacts: ContactUs[];
  public user: User;
  constructor(private userService: UserService,
              private sharedService: SharedService,
              private dialog: MatDialog,
              private readonly appState: AppState,
              ) {
    this.user = this.appState.user$();
  }

  ngOnInit() {
    this.contacts = of([
      {
        ContactTitle: 'Main Office',
        AddressLine1: '1867 Western Way',
        AddressLine2: 'Torrance, CA 90501',
        AddressLine3: '',
        AddressLine4: '',
        Tel: '(888) 683-2300',
        Fax: '(310) 328-1221',
        Email: 'customerservice@parcelpro.com',
        Hours: '07:00am to 06:00pm PST',
        Country: 'US',
      },
      {
        ContactTitle: 'UPS Capital Insurance Agency Limited',
        AddressLine1: '2 Lotus Park, The Causeway, Staines-Upon-Thames',
        AddressLine2: 'Middlesex TW18 3AG',
        AddressLine3: 'United Kingdom',
        AddressLine4: '',
        Tel: '+44 20 7949 0144',
        Fax: '',
        Email: 'risksolutionsuk@ups.com',
        Hours: 'Monday - Friday 09:00 to 17:30 GMT',
        Country: 'UK',
        Group: 'EU',
      },
      {
        ContactTitle: 'UPS Capital Versicherungsvermittlung GmbH',
        AddressLine1: 'Görlitzer Straße 1',
        AddressLine2: '41460 Neuss',
        AddressLine3: 'Deutschland',
        AddressLine4: '',
        Tel: '+49 69 6630 8330',
        Fax: '',
        Email: 'risksolutionsde@ups.com',
        Hours: 'Montag–Freitag 8:00 - 17:00 CET',
        Country: 'Other',
        Group: 'EU',
      },
      {
        ContactTitle: 'Downtown L.A. Office',
        AddressLine1: '607 S. Hill St. Suite P40',
        AddressLine2: 'Los Angeles, CA 90014',
        AddressLine3: '',
        AddressLine4: '',
        Tel: '(888) 683-6100',
        Fax: '(213) 327-0190',
        Email: 'customerservice@parcelpro.com',
        Hours: '10:00am to 06:00pm PST',
        Country: 'US',
      },      
      {
        ContactTitle: 'East Coast Sales Office',
        AddressLine1: '19 West 44th Street Suite 316',
        AddressLine2: 'New York, NY 10036',
        AddressLine3: '',
        AddressLine4: '',
        Tel: '(212) 242-7750',
        Fax: '(212) 242-7707',
        Email: 'customerservice@parcelpro.com',
        Hours: '10:00am to 06:00pm EST',
        Country: 'US',
      },      
      {
        ContactTitle: 'Miami Office',
        AddressLine1: '169 E. Flagler St. Suite 822',
        AddressLine2: 'Miami, FL 33131',
        AddressLine3: 'License Number: B1200264',
        AddressLine4: '',
        Tel: '(305) 358-7133',
        Fax: '(305) 358-7136',
        Email: 'customerservice@parcelpro.com',
        Hours: '10:30am to 06:00pm EST',
        Country: 'US',
      },      
      {
        ContactTitle: 'UPS Capital Versicherungsvermittlung GmbH - Succursale française',
        AddressLine1: '20 rue Escoffier',
        AddressLine2: '75012 Paris',
        AddressLine3: '',
        AddressLine4: '',
        Tel: '+33 1 73 01 84 55',
        Fax: '',
          Email: 'risksolutionsfr@ups.com',
          Hours: 'Lundi - Vendredi 8:00 - 17:00 CET',
        Country: 'France',    
        Group: 'EU',    
      },
      {
        ContactTitle: 'UPS Capital Versicherungsvermittlung GmbH - Sede secondaria di Milano',
        AddressLine1: 'Via Gaudenzio Fantoli, n. 15/8',
        AddressLine2: '20138 Milano',
        AddressLine3: 'Italia',
        AddressLine4: '',
        Tel: '+39 02 4527 9099',
        Fax: '',
          Email: 'risksolutionsit@ups.com',
          Hours: 'Lunedì-Venerdì 8:00 - 17:00 CET',
        Country: 'Italy',    
        Group: 'EU',    
      },
      {
        ContactTitle: 'Parcel Pro Hong Kong LTD',
        AddressLine1: 'Unit 1105-1106, 11/F, Tower A,',
        AddressLine2: 'Hung Hom Commercial Center,',
        AddressLine3: '39 Ma Tau Wai Road',
        AddressLine4: 'Hung Hom, Kowloon, Hong Kong',
        Tel: '(852) 3102-0229',
        Fax: '(852) 2331-3004',
          Email: 'pph@parcelpro.com.hk',
          Hours: 'Monday-Friday 09:00am to 18:00pm <br> Sat 09:00am to 13.30pm',
        Country: 'Other',
      },
      {
        ContactTitle: 'Parcel Pro Japan: Kansai (Kobe) Sales Office',
        AddressLine1: '22/F Kobe Kokusai Kaikan',
        AddressLine2: '8-1-6, Goko Dori, Chuo-ku, Kobe,',
        AddressLine3: 'Hyogo, 651-0087',
        AddressLine4: '',
        Tel: '(81) 078-570-5726',
        Fax: '(81) 078-570-5601',
          Email: 'operation@parcelpro.co.jp',
          Hours: 'Monday-Friday 09:00am to 18:00pm',
        Country: 'Other',
      },
      {
        ContactTitle: 'Parcel Pro Japan: Tokyo Head Office',
        AddressLine1: '1F Mihama Bldg.',
        AddressLine2: '3-16-5 Taito-ku,',
        AddressLine3: 'Tokyo 110-0016 Japan',
        AddressLine4: '',
        Tel: '(81) 3-6436-3137',
        Fax: '(81) 3-6436-3138',
          Email: 'operation@parcelpro.co.jp',
          Hours: 'Monday-Friday 09:00am to 18:00pm',
        Country: 'Other',        
      },
    ]);

    this.contacts.subscribe(
      (contacts) => {
        this.USContacts = contacts.filter(contact => contact.Country === 'US');
        this.internationalContacts = contacts.filter(contact => contact.Country !== 'US' && contact.Group !== 'EU');
        this.internationalEUContacts = contacts.filter(contact => contact.Group == 'EU');
      },
    );
  }
  public isDEGBUser() {
    if (this.user.CustomerId.substr(0, 2) === 'GB' || this.user.CustomerId.substr(0, 2) === 'DE') {
      return true;
    }
    return false;
  }

  public isEUUser() {
    if (this.user.CustomerId.substr(0, 2) === 'GB' || this.user.CustomerId.substr(0, 2) === 'DE'
        || this.user.CustomerId.substr(0, 2) === 'IT' || this.user.CustomerId.substr(0, 2) === 'FR') {
      return true;
    }    
      return false;
  }
  
  public openComplaintsDialog(event) {
    event.preventDefault();

    const dialogConfig: MatDialogConfig = {
      disableClose: true,
      width: '70%',
      data: {},
    };

    let dialogRef: MatDialogRef<ComplaintsComponent>;
    dialogRef = this.dialog.open(ComplaintsComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((result) => {
    });
  }
}
