import { NgModule } from '@angular/core';
import { CommonModule, SlicePipe } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AddressBookComponent } from './address-book/address-book.component';
import { AddressBook } from './models/address-book.model';
import { AddressBookService } from './services/address-book.service';
import { TranslateModule } from '@ngx-translate/core';
import { ManageAddressComponent } from './address-book/manage-address/manage-address.component';
import { S3FileDownloaderModule } from '../shared/components/s3-file-downloader/s3-file-downloader.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        SharedModule,
        TranslateModule,
        S3FileDownloaderModule,
    ],
    declarations: [
        AddressBookComponent,
        ManageAddressComponent,
    ],
    providers: [
        AddressBook,
        AddressBookService,
        SlicePipe,
    ],
    exports: [
        ManageAddressComponent,
    ]
})
export class AddressBookModule {
}
