import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';

@Component({
  selector: 'upsc-support-documents',
  templateUrl: './support-documents.component.html',
  styleUrls: ['./support-documents.component.scss'],
})
export class SupportDocumentsComponent implements OnInit {

  public fileList = [];

  constructor(private translateService: TranslateService) {

  }

  ngOnInit() {
    this.fileList = [
      { 'fileName': 'Safe_Packaging_Checklist.pdf', 'Label': this.translateService.instant('supportDocuments.SafeShippingPackagingChecklist') },
      { 'fileName': 'ParcelPro_Onboarding_Quick_Start_Guide.pdf', 'Label': this.translateService.instant('supportDocuments.ParcelProStartGuide') },
      { 'fileName': 'Parcel Pro_Jewelry_Shipping_Tips.pdf', 'Label': this.translateService.instant('supportDocuments.ParcelProJewelryShippingTips') },
      { 'fileName': 'Parcel Pro_Billing_Invoicing_FAQ.pdf', 'Label': this.translateService.instant('supportDocuments.ParcelProBillingandInvoicingFAQ') },
      { 'fileName': 'Parcel Pro_Minimizing_Shipping_Losses_FAQ.pdf', 'Label': this.translateService.instant('supportDocuments.MinimizingShippingLossesFAQ') },
      //{ 'fileName': 'ParcelPro_Members_Handbook.pdf', 'Label': this.translateService.instant('supportDocuments.ParcelProHandbook') },
      { 'fileName': 'Parcel Pro_Residential_Shipping_Message.pdf', 'Label': this.translateService.instant('supportDocuments.ResidentialShipping') },
      { 'fileName': 'ParcelPro_Members_Handbook.pdf', 'Label': this.translateService.instant('supportDocuments.ParcelProHandbook') },
      { 'fileName': 'UC PPI Claim Form.pdf', 'Label': this.translateService.instant('supportDocuments.UCPPIClaimForm') },
      { 'fileName': 'Commercial_Invoice.pdf', 'Label': this.translateService.instant('supportDocuments.CommercialInvoice') },
      { 'fileName': 'Parcel_Pro_High_Value_Retailers_Guide.pdf', 'Label': this.translateService.instant('supportDocuments.ParcelProHighValueRetailersGuide') },
      { 'fileName': 'AlterationDeclaration.pdf', 'Label': this.translateService.instant('supportDocuments.AlterationDeclaration') },
      { 'fileName': 'USAntiqueDeclaration.pdf', 'Label': this.translateService.instant('supportDocuments.USAntique') },
      { 'fileName': 'SED.pdf', 'Label': this.translateService.instant('supportDocuments.SEDform') },
      { 'fileName': 'Parcel Pro_Export_Address_Book_UPS.pdf', 'Label': this.translateService.instant('supportDocuments.ExportAddressBookUPS') },
      { 'fileName': 'Parcel Pro_Export_Address_Book_FedEx.pdf', 'Label': this.translateService.instant('supportDocuments.ExportAddressBookFedEx') },
      { 'fileName': 'JADE_RUBY_CERTIFICATE.pdf', 'Label': this.translateService.instant('supportDocuments.JadeRubyCertificate') },
      { 'fileName': 'watch_worksheet.pdf', 'Label': this.translateService.instant('supportDocuments.WatchWorksheet') },
      { 'fileName': 'USMCA-UPS.pdf', 'Label': this.translateService.instant('supportDocuments.USMCAFormUPS') },
      { 'fileName': 'USMCA-FedEx.pdf', 'Label': this.translateService.instant('supportDocuments.USMCAFormFedEx') }
    ];
  }

  public generateButtonId(fileName: string) {
    return _.kebabCase(fileName);
  }
}