<div class="restricted-zip-codes white-sheet-content">
  <div class="ups-page-header">
    <h1 class="page-title"> {{'RestrictedZipCodes.title'|translate}} </h1>
  </div>
  <div class="wrapper">
    <div class="instruction">
      {{'RestrictedZipCodes.instruction1'|translate}}
    </div>
    <div class="sections">
      <div class="section">
        <strong> {{'LosAngeles'|translate}} </strong>
        <div> 90007 </div>
        <div> 90013 </div>
        <div> 90014 </div>
        <div> 90015 </div>
        <div> 90017 </div>        
        <div> 90057 </div>
      </div>
      <div class="section">
        <strong> {{'BeverlyHills'|translate}} </strong>
        <div> 90209** </div>
        <div> 90210** </div>
        <div> 90211** </div>
        <div> 90212** </div>
        <div> 90213** </div>
      </div>
      <div class="section">
        <strong> {{'NewYork'|translate}} </strong>
        <div> 10011** </div>
        <div> 10016** </div>
        <div> 10017 </div>
        <div> 10019** </div>
        <div> 10021** </div>
        <div> 10036 </div>
        <div> 10065** </div>
        <div> 10075** </div>
      </div>
    </div>
    <div class="instruction">
      {{'RestrictedZipCodes.instruction2'|translate}}
    </div>
    <div class="instruction">
      {{'RestrictedZipCodes.instruction3'|translate}}
    </div>
    <div class="instruction">
      <p><strong> {{'RestrictedZipCodes.instruction4'|translate}} </strong></p>
      <p> {{'RestrictedZipCodes.instruction5'|translate}} </p>
    </div>
    <div class="sections">
      <div class="section">
        <p>{{'RestrictedZipCodes.QualityLabs.AddressLine1'|translate}}</p>
        <p>{{'RestrictedZipCodes.QualityLabs.AddressLine2'|translate}}</p>
        <p>{{'RestrictedZipCodes.QualityLabs.AddressLine3'|translate}}</p>
        <p>{{'RestrictedZipCodes.QualityLabs.AddressLine4'|translate}}</p>
      </div>
    </div>
    <div class="instruction">
      <strong>{{'RestrictedZipCodes.instruction6'|translate}}</strong>
    </div>
    <div class="sections">
      <div class="section">
        <p>{{'RestrictedZipCodes.ORMResearch.AddressLine1'|translate}}</p>
        <p>{{'RestrictedZipCodes.ORMResearch.AddressLine2'|translate}}</p>
        <p>{{'RestrictedZipCodes.ORMResearch.AddressLine3'|translate}}</p>
        <p>{{'RestrictedZipCodes.ORMResearch.AddressLine4'|translate}}</p>
        <p>{{'RestrictedZipCodes.ORMResearch.AddressLine5'|translate}}</p>
      </div>
    </div>
    <div class="instruction">
      <strong>{{'RestrictedZipCodes.instruction7'|translate}}</strong>
    </div>
    <div class="sections">
      <div class="section">
        <p><label>{{'RestrictedZipCodes.UPSShipments.AddressLine1'|translate}}</label> </p>
        <p>{{'RestrictedZipCodes.UPSShipments.AddressLine2'|translate}}</p>
        <p>{{'RestrictedZipCodes.UPSShipments.AddressLine3'|translate}}</p>
        <p>{{'RestrictedZipCodes.UPSShipments.AddressLine5'|translate}}</p>
        <p>{{'RestrictedZipCodes.UPSShipments.AddressLine6'|translate}}</p>
      </div>
      <div class="section">
        <p><label>{{'RestrictedZipCodes.FedExShipments.AddressLine1'|translate}}</label> </p>
        <p>{{'RestrictedZipCodes.FedExShipments.AddressLine2'|translate}}</p>
        <p>{{'RestrictedZipCodes.FedExShipments.AddressLine3'|translate}}</p>
        <p>{{'RestrictedZipCodes.FedExShipments.AddressLine4'|translate}}</p>
        <p>{{'RestrictedZipCodes.FedExShipments.AddressLine5'|translate}}</p>
        <p>{{'RestrictedZipCodes.FedExShipments.AddressLine6'|translate}}</p>
      </div>
    </div>
    <div class="instruction terms">
      <p>{{'RestrictedZipCodes.Terms.terms1a'|translate}}<label>
        <a href="javascript:;" (click)="openTermsAndConditionsDialog($event)" >{{'TermsAndConditions'|translate}}</a></label> {{'RestrictedZipCodes.Terms.terms1b'|translate}} <label>
        <a href="javascript:;" (click)="openShippingGuideDialog($event)" >{{'RestrictedZipCodes.Terms.terms1c'|translate}}</a></label>.</p>
      <p>{{'RestrictedZipCodes.Terms.terms2'|translate}}</p>
      <p>{{'RestrictedZipCodes.Terms.terms3'|translate}}</p>
    </div>
  </div>
</div>