import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { takeWhile }from 'rxjs/operators';

import { UserTermsConditionsComponent } from '../../account/user-settings/user-terms-conditions/user-terms-conditions.component';
import { UserService } from '../../shared/services/user/user.service';
import { NotificationType } from '../../shared/models/notification-type';
import { NotificationService } from '../../shared/services/notification/notification.service';
import { ErrorHandlerService } from '../../shared/services/error-handler/error-handler.service';
import { ShippingGuideDialogComponent } from '../../shared/components/shipping-guide-dialog/shipping-guide-dialog.component';

@Component({
  selector: 'upsc-restricted-zip-codes',
  templateUrl: './restricted-zip-codes.component.html',
  styleUrls: ['./restricted-zip-codes.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RestrictedZipCodesComponent implements OnInit, OnDestroy {

  private term: any;

  constructor(private dialog: MatDialog,
    private userService: UserService,
    private errorHandlerService: ErrorHandlerService,
    private notificationService: NotificationService) {

  }

  ngOnInit() {
    this.userService.getTermsSigned().pipe(
      takeWhile(() => !this.term))
      .subscribe(
        (term) => {
          this.term = term;
        },
        (err) => {
          this.notificationService.notify(
            this.errorHandlerService.getHttpErrorMessage(err),
            'Error Loading Terms',
            NotificationType.ERROR);
        });
  }

  public openTermsAndConditionsDialog(event) {
    event.preventDefault();

    const dialogConfig: MatDialogConfig = {
      disableClose: true,
      width: '70%',
      data: {
        termsConditions: this.term.TermsCondition,
      },
    };

    let dialogRef: MatDialogRef<UserTermsConditionsComponent>;
    dialogRef = this.dialog.open(UserTermsConditionsComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((result) => {
    });
  }

  public openShippingGuideDialog(event) {
    event.preventDefault();

    const dialogConfig: MatDialogConfig = {
      disableClose: true,
      width: '70%',
      data: {        
      },
    };

    let dialogRef: MatDialogRef<ShippingGuideDialogComponent>;
    dialogRef = this.dialog.open(ShippingGuideDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((result) => {
    });
  }

  ngOnDestroy() {

  }

}
