import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'orderItemUnit'
})
export class OrderItemUnitPipe implements PipeTransform {

    transform(obj: any): any {
        switch (obj.name) {
            case 'SmallBox':
                return obj.value + ' Carton(s) (' + obj.value * 20 + ')';
            case 'Pouch':
                return obj.value + ' Pak(s) (' + obj.value * 100 + ')';
            case 'FedexInternationalAirbillPouch':
                return obj.value + '  Pouches';
            case 'FedexSmallBox':
                return obj.value + ' Boxes';
            case 'FedexMediumBox':
                return obj.value + ' Boxes';
            case 'FedexLargeBox':
                return obj.value + ' Boxes';
            case 'FedexSmallPak':
                return obj.value + ' Pak(s)';
            case 'FedexLargePak':
                return obj.value + ' Pak(s)';
            case 'FedExInternationalAirWayBill':
                return obj.value + ' Waybills';
            case 'FedExDomesticAirbillPouch':
                return obj.value + ' Pouches';
            case 'ThermalRoll':
                return obj.value + ' Roll(s) (' + obj.value * 320 + ')';
            case 'PeelStick':
                return obj.value + ' Pack(s) (' + obj.value * 50 + ')';
            case 'PaddedPack':
                return obj.value + ' Pak(s) (' + obj.value * 25 + ')';
            case 'Envelopes':
                return obj.value + ' Carton (' + obj.value * 50 + ')';
            case 'LargeBox':
                return obj.value + ' Carton(s) (' + obj.value * 20 + ')';
            case 'MediumBox':
                return obj.value + ' Carton(s) (' + obj.value * 20 + ')';
            case 'InternationalPouch':
                return obj.value + ' Pouches';
            case 'FedexLaserLabel':
                return obj.value + ' Label';
            case 'FedexEnvelopePouch':
                return obj.value + ' Envelopes';
            case 'FedexLegalSizeEnvelope':
                return obj.value + ' Envelopes';
            default:
                return obj.value;
        }
    }
}
